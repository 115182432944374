import React, { useContext, useEffect, useState } from "react";
import spacesStyles from "../../../../styles/pages/spaces.module.scss";
import moment from "moment";
import { CloseOutlined, FieldTimeOutlined } from "@ant-design/icons";
import {
  Dropdown,
  Image,
  MenuProps,
  Modal,
  Popover,
  Steps,
  notification,
} from "antd";
import { FileIcon, defaultStyles } from "react-file-icon";
import { useQuery, useQueryClient } from "react-query";
import {
  AddReaction,
  DeleteMessage,
  FetchUsersMessages,
  GetReactions,
} from "../../../../services/chats";
import ForwardUsers from "../../forwardUsers";
import { FetchSpaces } from "../../../../services/spaces";
import { FetchGroups } from "../../../../services/groups";
import { MessageContext } from "../../../../App";

const ChatMessage = ({
  message,
  group,
  user,
  setReply,
  setEdit,
  setRoomId,
  onDelete,
}: any) => {
  const queryClient = useQueryClient();
  const [User, setUser] = useState<any>({});
  const [ForwardSearch, setForwardSearch] = useState<any>("");
  const [VisableMenu, setVisableMenu] = useState(false);
  const [newMessage, SetNewMessage] = useContext<any>(MessageContext);
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUser(t);
  }, []);
  const messageClick = (e: any) => {
    if (!VisableMenu) {
      if (e.preventDefault != undefined) e.preventDefault();
      if (e.stopPropagation != undefined) e.stopPropagation();
      setVisableMenu(true);
    }
  };
  const deleteMessage = () => {
    onDelete(message.id);
    DeleteMessage(message.id).then(() => {
      queryClient.invalidateQueries("groups");
      queryClient.invalidateQueries("UsersChats");
    });
  };
  const messageMenuItems = (): MenuProps["items"] => {
    const items: MenuProps["items"] = [];
    if (
      !(
        (group &&
          user?.props?.find((o: any) => o.perm === 1 || o.perm === 3)?.allow ===
            1) ||
        (!group &&
          (user?.members
            ?.find((o: any) => o.id === User.id)
            ?.props?.find((o: any) => o.perm === 1)?.allow === 0 ||
            user?.members
              ?.find((o: any) => o.id === User.id)
              ?.props?.find((o: any) => o.perm === 1) === undefined))
      )
    ) {
      items.push({
        key: 0,
        icon: <img src="/images/messageMenu/link-backward.png" alt="" />,
        label: "Reply",
        onClick() {
          setReply({
            id: message?.id,
            message: message?.type === 1 ? message?.text : "Replies to",
            sender:
              message?.is_mine === 0
                ? group
                  ? message?.sender?.name
                  : user?.name
                : "Me",
            is_corrected: false,
          });
        },
      });
    }
    items.push(
      {
        key: 1,
        icon: <img src="/images/messageMenu/link-forward.png" alt="" />,
        label: "Forward",
        onClick() {
          Modal.info({
            className: "addspaceModal",
            centered: true,
            title: "Forward message to",
            autoFocusButton: null,
            icon: (
              <img
                style={{
                  transform: "rotateY(180deg)",
                  display: "inline-block",
                }}
                src="/images/Arrow_Undo_Up_Left.svg"
                alt=""
              />
            ),
            content: (
              <div>
                <ForwardUsers
                  userData={data?.json}
                  spaceData={spaces?.data?.json}
                  groupsData={groupsData?.data?.json}
                  message={message}
                  setSearch={(data: any) => {
                    setForwardSearch(data);
                  }}
                  onSubmit={() => {
                    queryClient.invalidateQueries("Messages");
                    queryClient.invalidateQueries("groups");
                    queryClient.invalidateQueries("UsersChats");
                  }}
                />
              </div>
            ),
            closeIcon: (
              <>
                <CloseOutlined color="#fff" />
              </>
            ),
            maskClosable: true,
            closable: true,
            footer: [],
          });
        },
      }
      // {
      //   key: 2,
      //   icon: <img src="/images/messageMenu/pin.png" alt="" />,
      //   label: "Pin",
      // },
      // {
      //   key: 3,
      //   icon: <img src="/images/messageMenu/checkmark-circle.png" alt="" />,
      //   label: "Select",
      // }
    );
    if (message?.type === 1) {
      items.push({
        key: 4,
        icon: <img src="/images/messageMenu/copy.png" alt="" />,
        label: "Copy",
        onClick() {
          navigator.clipboard.writeText(message?.text);
          notification.open({
            message: "text copied to clipboard successfully",
          });
          setVisableMenu(false);
        },
      });
    }
    // items.push({
    //   key: 5,
    //   icon: (
    //     <img
    //       src="/images/messageMenu/note-add.png"
    //       alt=""
    //       style={{ flexShrink: 0 }}
    //     />
    //   ),
    //   label: "Assign as a task",
    // });
    if (
      !(
        (group && user?.props?.find((o: any) => o.perm === 4)?.allow === 0) ||
        (!group &&
          (user?.members
            ?.find((o: any) => o.id === User.id)
            ?.props?.find((o: any) => o.perm === 4)?.allow === 0 ||
            user?.members
              ?.find((o: any) => o.id === User.id)
              ?.props?.find((o: any) => o.perm === 4) === undefined))
      )
    ) {
      if (message?.is_mine === 1 && message?.type === 1) {
        items.push({
          key: 6,
          icon: <img src="/images/messageMenu/pencil-edit.png" alt="" />,
          label: "Edit",
          onClick() {
            setEdit({
              id: message?.id,
              message: message?.text,
            });
            setVisableMenu(false);
          },
        });
      }
    }
    if (
      !(
        group &&
        user?.members
          ?.find((o: any) => o.id === User.id)
          ?.props?.find((o: any) => o.perm === 5)?.allow === 0
      )
    ) {
      if (message?.is_mine === 0 && message?.type === 1 && group) {
        items.push({
          key: 7,
          icon: <img src="/images/messageMenu/pencil-edit.png" alt="" />,
          label: "Edit",
          onClick() {
            setEdit({
              id: message?.id,
              message: message?.text,
            });
            setVisableMenu(false);
          },
        });
      }
    }
    if (message?.is_edited && message?.type === 1) {
      items.push({
        key: 8,
        icon: <img src="/images/messageMenu/license-draft.png" alt="" />,
        label: "Editing log",
        onClick() {
          Modal.info({
            className: "addspaceModal",
            centered: true,
            title: "Editing log",
            autoFocusButton: null,
            icon: <></>,
            content: (
              <div>
                <Steps
                  progressDot
                  className="editinglog__steps"
                  direction="vertical"
                  items={[
                    ...message.old_messages
                      ?.filter((msg: any) => msg.causer_type)
                      ?.map((msg: any) => ({
                        title: (
                          <div className="date__log">
                            {moment(msg.properties.old.updated_at).format(
                              "DD MMM YYYY [AT] hh:mm A"
                            )}
                          </div>
                        ),
                        description: (
                          <div className="messagebox__log">
                            <p>{msg.properties.old.text}</p>
                            <div className="editedby__log">
                              {"Edited by @" +
                                (msg?.causer?.id === User?.id
                                  ? "Me"
                                  : msg?.causer?.full_name)}
                            </div>
                          </div>
                        ),
                      })),
                    {
                      title: (
                        <div className="date__log">
                          {moment(message.modified_at).format(
                            "DD MMM YYYY [AT] hh:mm A"
                          )}
                        </div>
                      ),
                      description: (
                        <div className="messagebox__log">
                          <p>{message.text}</p>
                          <div className="editedby__log">
                            {"Edited by @" +
                              (message?.user?.id === User?.id
                                ? "Me"
                                : message?.user?.full_name)}
                          </div>
                        </div>
                      ),
                    },
                  ].reverse()}
                />
              </div>
            ),
            closeIcon: (
              <>
                <CloseOutlined color="#fff" />
              </>
            ),
            maskClosable: true,
            closable: true,
            footer: [],
          });
        },
      });
    }
    if (message?.type === 1) {
      items.push({
        key: 9,
        icon: <img src="/images/messageMenu/eraser.png" alt="" />,
        label: "Correct",
        onClick() {
          setReply({
            id: message?.id,
            message: message?.type === 1 ? message?.text : "Replies to",
            sender:
              message?.is_mine === 0
                ? group
                  ? message?.sender?.name
                  : user?.name
                : "Me",
            is_corrected: true,
          });
          setVisableMenu(false);
        },
      });
    }
    if (
      !(
        (group &&
          user?.members
            ?.find((o: any) => o.id === User.id)
            ?.props?.find((o: any) => o.perm === 4)?.allow === 0) ||
        (!group &&
          (user?.members
            ?.find((o: any) => o.id === User.id)
            ?.props?.find((o: any) => o.perm === 5)?.allow === 0 ||
            user?.members
              ?.find((o: any) => o.id === User.id)
              ?.props?.find((o: any) => o.perm === 5) === undefined))
      )
    )
      if (message?.is_mine === 1)
        items.push({
          key: 10,
          icon: (
            <img style={{ width: 18 }} src="/images/delete-02.png" alt="" />
          ),
          label: "Remove",
          onClick() {
            deleteMessage();
            setVisableMenu(false);
          },
        });

    return items;
  };

  useEffect(() => {
    var body = document.querySelector("body");

    body !== null &&
      body !== null &&
      body.addEventListener("mousedown", (event: any) => {
        if (typeof event?.target?.className === "string") {
          if (
            !event?.target?.className.includes("reaction") &&
            !event?.target?.className.includes("menuBtn")
          ) {
            if (event?.button == 0) {
              // left click for mouse
              if (event?.target?.className !== "messageMenu")
                setVisableMenu(false);
            } else if (event.button == 1) {
              // wheel click for mouse
              if (event?.target?.className !== "messageMenu")
                setVisableMenu(false);
            } else if (event?.button == 2) {
              // right click for mouse
              if (event?.target?.className !== "messageMenu")
                setVisableMenu(false);
            }
          }
        } else {
          setVisableMenu(false);
        }
      });
    window.oncontextmenu = function (e: any) {
      if (e.target.className !== "messageMenu") setVisableMenu(false);
    };
  }, []);
  // const Reactions = useQuery("Reactions", async () => GetReactions(), {
  //   cacheTime: 0,
  //   staleTime: Infinity,
  // });
  const { data, status, isLoading } = useQuery(
    ["UsersChats", ForwardSearch],
    async () => FetchUsersMessages(1, ForwardSearch),
    {
      cacheTime: 0,
      staleTime: 10000,
    }
  );
  const spaces = useQuery("Spaces", async () => FetchSpaces(), {
    cacheTime: 0,
    staleTime: 10000,
  });
  const groupsData = useQuery(
    ["groupsData", 0, ""],
    async () => FetchGroups(1, 0, ""),
    {
      cacheTime: 0,
      staleTime: 10000,
    }
  );
  return (
    <div
      id={message?.id && message?.id}
      onContextMenu={(e) => {
        if (e.preventDefault != undefined) e.preventDefault();
        if (e.stopPropagation != undefined) e.stopPropagation();
      }}
      className={message?.is_mine === 1 ? spacesStyles.mine : ""}
    >
      {message?.is_mine === 0 && (
        <img
          className={spacesStyles.userIcon}
          src={message?.user?.profile_image?.url}
          alt=""
        />
      )}
      <Dropdown
        trigger={["contextMenu"]}
        menu={{
          items: messageMenuItems(),
          id: `messageMenu${message?.id}`,
          style: { width: "140px", borderRadius: "12px" },
        }}
        dropdownRender={(menu) => (
          <div className={spacesStyles.messageMenuCon}>
            <div>{menu}</div>
            {/* <div className={spacesStyles.reactionsCon}>
              {Reactions.data?.json.map((reaction: any) => (
                <div
                  onClick={() => {
                    console.log("click");
                    //FIXME - close dropdown after click
                    AddReaction({
                      message_id: message?.id,
                      reaction_id: reaction?.id,
                    }).then(() => {
                      queryClient.invalidateQueries("Messages");
                      queryClient.invalidateQueries("groups");
                      queryClient.invalidateQueries("UsersChats");
                    });
                  }}
                  className={spacesStyles.reaction}
                >
                  <img src={reaction?.reaction?.url} alt="" />
                </div>
              ))}
            </div> */}
          </div>
        )}
      >
        <div className={spacesStyles.messageBox}>
          {message?.is_forwarded ? (
            <div className={spacesStyles.forwarded}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9932 7.86781L14.3554 6.22432C13.1753 5.04016 12.7128 4.40314 12.0738 4.62834C11.2771 4.90914 11.5393 6.68093 11.5393 7.27909C10.3006 7.27909 9.01267 7.16899 7.79154 7.39839C3.76049 8.1557 2.5 11.4296 2.5 15.0002C3.64092 14.192 4.78069 13.331 6.15194 12.9565C7.86364 12.4889 9.77533 12.712 11.5393 12.712C11.5393 13.3102 11.2771 15.082 12.0738 15.3627C12.7979 15.6179 13.1753 14.9509 14.3554 13.7667L15.9932 12.1232C16.9977 11.1152 17.5 10.6112 17.5 9.99558C17.5 9.38 16.9977 8.87591 15.9932 7.86781Z"
                  stroke="#3A3A3C"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>{" "}
              Forwarded
            </div>
          ) : (
            ""
          )}
          {message?.replied && message?.replied !== null && (
            <div className={spacesStyles.replyBox}>
              <h5>{message?.replied?.content?.split(":")[0]}</h5>
              <div className={spacesStyles.repliedMessage}>
                {message?.replied?.content?.split(":")[1]}
              </div>
            </div>
          )}
          {message?.is_mine === 0 && <h3>{message?.user?.full_name}</h3>}
          {!message?.type && <p> {message?.text}</p>}
          {message?.type &&
            message?.type !== null &&
            message?.type !== "Empty Message" && <p> {message?.text}</p>}
          {message?.type === 4 && (
            <p>
              <Image className="messageImg" src={message?.image[0]?.url} />
            </p>
          )}
          {message?.type === 2 && (
            <audio src={message?.voice[0]?.url} controls />
          )}
          {message?.type === 3 && (
            <audio src={message?.audio[0]?.url} controls />
          )}
          {message?.type === 5 && (
            <video src={message?.video[0]?.url} controls />
          )}
          {message?.type === 6 && (
            <div className={spacesStyles.fileCard}>
              <div style={{ display: "flex" }}>
                <div style={{ marginInlineEnd: 10, marginTop: 3 }}>
                  <FileIcon
                    extension={
                      message?.doc[0]?.url.split(".")[
                        message?.doc[0]?.url.split(".").length - 1
                      ]
                    }
                    {...defaultStyles.docx}
                  />
                </div>
                <div>
                  <div className={spacesStyles.fileName}>
                    {message?.doc[0]?.file_name}
                  </div>
                  <div className={spacesStyles.fileDes}>
                    {(message?.doc[0]?.size / (1024 * 1024)).toFixed(2) + "MB "}
                    •{" "}
                    {message?.doc[0]?.url.split(".")[
                      message?.doc[0]?.url.split(".").length - 1
                    ].length < 10
                      ? message?.doc[0]?.url
                          .split(".")
                          [
                            message?.doc[0]?.url.split(".").length - 1
                          ].toUpperCase()
                      : message?.doc[0]?.type.toUpperCase()}
                  </div>
                </div>
              </div>
              <a href={message?.doc[0]?.url} download target="_blank">
                <img
                  className={spacesStyles.downFileIcon}
                  src={"/images/download.png"}
                  alt=""
                />
              </a>
            </div>
          )}
          {/* {message?.reactions && message?.reactions.length > 0 && (
            <div className={spacesStyles.messageReactions}>
              {message?.reactions?.map((reaction: any) => (
                <Popover
                  className="reationUsers"
                  content={<></>}
                  title={
                    <div className={"reactionInfo"}>
                      <img src={reaction.user.profile_image.url} alt="" />
                      {reaction.user.full_name}
                    </div>
                  }
                >
                  <div className={spacesStyles.messageReaction}>
                    <img src={reaction?.reaction?.url} alt="" />
                  </div>
                </Popover>
              ))}
            </div>
          )} */}
          <div className={spacesStyles.time}>
            {message?.old_messages?.filter((msg: any) => msg.causer_type)
              ?.length > 0 &&
              "Edited by @" +
                (message?.old_messages?.filter((msg: any) => msg.causer_type)[
                  message?.old_messages?.filter((msg: any) => msg.causer_type)
                    .length - 1
                ]?.causer?.id === User?.id
                  ? "Me"
                  : message?.old_messages?.filter(
                      (msg: any) => msg.causer_type
                    )[
                      message?.old_messages?.filter(
                        (msg: any) => msg.causer_type
                      ).length - 1
                    ]?.causer?.full_name) +
                " • "}
            {message.is_corrected ? "✓ Corrected • " : ""}
            {moment.utc(message?.created_at).local().format("MM-DD hh:mm A")}
            {message?.is_mine === 1 && (
              <div className={spacesStyles.sendedIconCon}>
                {message?.is_seen === "pending" && <FieldTimeOutlined />}
                {message?.is_seen === 0 && <img src="/images/tick.png" />}
                {message?.is_seen === 1 && <img src="/images/two-ticks.png" />}
              </div>
            )}
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default ChatMessage;

import { useEffect, useRef, useState } from "react";
import playerStyles from "./styles.module.scss";

const play = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8906 12.846C19.5371 14.189 17.8667 15.138 14.5257 17.0361C11.296 18.8709 9.6812 19.7884 8.37983 19.4196C7.8418 19.2671 7.35159 18.9776 6.95624 18.5787C6 17.6139 6 15.7426 6 12C6 8.2574 6 6.3861 6.95624 5.42132C7.35159 5.02245 7.8418 4.73288 8.37983 4.58042C9.6812 4.21165 11.296 5.12907 14.5257 6.96393C17.8667 8.86197 19.5371 9.811 19.8906 11.154C20.0365 11.7084 20.0365 12.2916 19.8906 12.846Z"
      fill="#636366"
    />
  </svg>
);

const pause = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 7C4 5.58579 4 4.87868 4.43934 4.43934C4.87868 4 5.58579 4 7 4C8.41421 4 9.12132 4 9.56066 4.43934C10 4.87868 10 5.58579 10 7V17C10 18.4142 10 19.1213 9.56066 19.5607C9.12132 20 8.41421 20 7 20C5.58579 20 4.87868 20 4.43934 19.5607C4 19.1213 4 18.4142 4 17V7Z"
      fill="url(#paint0_linear_2567_8522)"
    />
    <path
      d="M14 7C14 5.58579 14 4.87868 14.4393 4.43934C14.8787 4 15.5858 4 17 4C18.4142 4 19.1213 4 19.5607 4.43934C20 4.87868 20 5.58579 20 7V17C20 18.4142 20 19.1213 19.5607 19.5607C19.1213 20 18.4142 20 17 20C15.5858 20 14.8787 20 14.4393 19.5607C14 19.1213 14 18.4142 14 17V7Z"
      fill="url(#paint1_linear_2567_8522)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2567_8522"
        x1="10.27"
        y1="2.88"
        x2="-2.0529"
        y2="7.83414"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BF4034" />
        <stop offset="1" stop-color="#9B1724" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2567_8522"
        x1="20.27"
        y1="2.88"
        x2="7.9471"
        y2="7.83414"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BF4034" />
        <stop offset="1" stop-color="#9B1724" />
      </linearGradient>
    </defs>
  </svg>
);

const AudioPlayer = ({ src }: { src?: string }) => {
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef(new Audio(src));

  const { duration } = audioRef.current;

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
    };
  }, []);

  const startTimer = () => {
    setInterval(() => {
      setTrackProgress(audioRef.current.currentTime);
    }, 1000);
  };

  useEffect(() => {
    if (trackProgress === duration) {
      setIsPlaying(false);
      setTrackProgress(0);
    }
  }, [trackProgress]);

  return (
    <>
      <button
        className={playerStyles.play_pause}
        style={{ "--progress": `${(trackProgress / duration) * 100}` } as any}
        onClick={() => {
          if (src) setIsPlaying(!isPlaying);
        }}
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          className={playerStyles.circular_progress}
        >
          <circle className={playerStyles.bg}></circle>
          {(trackProgress / duration) * 100 !== 0 && (
            <circle className={playerStyles.fg}></circle>
          )}
        </svg>
        <div>{isPlaying ? pause : play}</div>
      </button>
    </>
  );
};

export default AudioPlayer;

import { AxiosRequestConfig } from "axios";
import ApiService from "../../utils/api/api-service";
import ApiResponse, { isError } from "../../utils/api/models/api-response";
import { ApiErrorNotification } from "../../utils/ui/notificationService";

class UsersService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: "https://cascade.wealthcoders.ae/api/",
      ...config,
    });
  }

  public async Fetch(page: number, role: string): Promise<ApiResponse<any>> {
    return this.get(
      `/user/all?perPage=300&page=${page}${role !== "" ? "&role=" + role : ""}`
    );
  }

  async add(data: any): Promise<ApiResponse<any>> {
    return this.post(`/user/store`, data);
  }
  async edit(data: any): Promise<ApiResponse<any>> {
    return this.post(`/user/update`, data);
  }
}

const usersService = new UsersService();

export const FetchUsers = async (page: number, role: string) => {
  const result: any = await usersService.Fetch(page, role);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};

export const PostUser = async (data: any) => {
  const result = await usersService.add(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const UpdateUser = async (data: any) => {
  const result = await usersService.edit(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};

import React, { useEffect, useState } from "react";
import usersStyles from "../../styles/pages/profile.module.scss";
import { Skeleton } from "antd";
import ChatRooms from "../room/chatRooms";
import { useNavigate } from "react-router-dom";
import UserCompList from "./UserCompList";
const UsersCon = ({ data, Loading, setLoading, setUser, change,setUserEdit }: any) => {
  const history = useNavigate();

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  return (
    <div>
      {Loading ? (
        <div className={usersStyles.groupsSkeleton}>
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
        </div>
      ) : (
        <>
          {data?.length > 0 ? (
            <UserCompList
              groups
              data={data}
              setUser={(data: any) => {
                setUser(data);
              }}
              setUserEdit={(data: any) => {
                setUserEdit(data);
              }}
            />
          ) : (
            data?.length === 0 && <div className={usersStyles.emptypage}></div>
          )}
        </>
      )}
    </div>
  );
};

export default UsersCon;

import { AxiosRequestConfig } from "axios";
import ApiService from "../../utils/api/api-service";
import ApiResponse, { isError } from "../../utils/api/models/api-response";
import { ApiErrorNotification } from "../../utils/ui/notificationService";

class ContactsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: "https://cascade.wealthcoders.ae/api/",
      ...config,
    });
  }

  public async Fetch(
    user_id: any,
    supervisors: any
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/contact/all?${user_id !== 0 ? "user_id=" + user_id : "is_mine=1"}${
        supervisors && supervisors !== 0
          ? "&supervisors_ids[0]=" + supervisors
          : ""
      }`
    );
  }
  public async add(data: any): Promise<ApiResponse<any>> {
    return this.post(`/user/store`, data);
  }
  public async addPermission(data: any): Promise<ApiResponse<any>> {
    return this.post(`/contact/store`, data);
  }
  public async addFile(data: any): Promise<ApiResponse<any>> {
    return this.post(`/file`, data);
  }
  public async favourite(data: any): Promise<ApiResponse<any>> {
    return this.post(`/favorite/store`, data);
  }
}

const contactsService = new ContactsService();

export const FetchContacts = async (user_id: any, supervisors?: any) => {
  const result: any = await contactsService.Fetch(user_id, supervisors);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    // const currentUserId = JSON.parse(
    //   window.localStorage.getItem("user") as string
    // ).id;
    // const filteredResult = result.json.filter(
    //   (contact: any) => contact.contact.created_by.id === currentUserId
    // );
    return result;
  }
};

export const AddContact = async (data: any) => {
  const result: any = await contactsService.add(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const AddContactPermission = async (data: any) => {
  const result: any = await contactsService.addPermission(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const AddFile = async (data: any) => {
  const result: any = await contactsService.addFile(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const AddFavourite = async (data: any) => {
  const result: any = await contactsService.favourite(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};

import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import UsersCon from "../../components/call/UserCon";
import { useQuery } from "react-query";
import { MessageContext } from "../../App";
import callsStyles from "../../styles/pages/call.module.scss";
import { Segmented } from "antd";
import { FetchCalls } from "../../services/calls";
import VideoRoom from "../../components/call/VideoRoom";
const Call: React.FC = () => {
  const [RoomId, setRoomId] = useState(0);
  const [SubRoomId, setSubRoomId] = useState(0);
  const [GroupsLoading, setGroupsLoading] = useState(true);
  const [user, setUser] = useState<any>({});
  const [TypeOfCall, setTypeOfCall] = useState<string>("Audio Calls");
  const [TypeOfCallStatus, setTypeOfCallStatus] = useState<string>("0");

  const { data, status, isLoading, refetch } = useQuery(
    ["Calls", TypeOfCallStatus], // Include TypeOfCallStatus in the query key array
    async () =>
      FetchCalls(
        0,
        JSON.parse(window.localStorage.getItem("user") as string).id,
        TypeOfCallStatus
      ),
      {
        cacheTime: 0,
        staleTime: 10000,
      }
  );

  const [newMessage,SetNewMessage] = useContext<any>(MessageContext);
  useEffect(() => {
    if (newMessage && Object.keys(newMessage).length > 0) {
      refetch();
    }
  }, [newMessage]);

  useEffect(() => {
    // Enable the query when TypeOfCallStatus changes
    if (TypeOfCallStatus !== null) {
      refetch();
    }
  }, [TypeOfCallStatus]);

  return (
    <Row style={{ height: "100%" }}>
      <Col md={7}>
        <div className={callsStyles.segmented__container}>
          <Segmented<string>
            options={["Audio Calls", "Video Calls"]}
            onChange={(value) => {
              setTypeOfCall(value);
              setTypeOfCallStatus(value === "Audio Calls" ? "0" : "1");
            }}
          />
        </div>

        <UsersCon
          data={data?.json}
          setUser={(data: any) => {
            setUser(data);
          }}
          setLoading={(status: boolean) => setGroupsLoading(status)}
          Loading={GroupsLoading}
          user={user}
        />
      </Col>
      <Col md={17}></Col>
    </Row>
  );
};

export default Call;

import { Avatar } from "antd";
import React, { useEffect, useRef, useState } from "react";
import stylescall from "../../styles/pages/call.module.scss";

export const VideoPlayer = ({
  user,
  enableCamera,
  enableMicrophone,
  First,
  callType,
  users,
  UsersInfo,
  heightOfVideo,
}: any) => {
  const ref = useRef<any>(null);
  const refu = useRef<any>(null);
  const [Playable, setPlayable] = useState<boolean>(true);
  const [UserImage, setUserImage] = useState<string>("");
  const [UserName, setUserName] = useState<string>("");
  const audioRef = useRef<any>(null); // Added audioRef

  const findUserUID =
    users[
      users?.findIndex(
        (obj: any) =>
          obj.uid ===
          JSON.parse(window.localStorage.getItem("user") as string).id
      )
    ];

  const findUserImage =
    UsersInfo[UsersInfo?.findIndex((obj: any) => obj?.id === user?.uid)]
      .profile_image?.url;

  const findUserUserName =
    UsersInfo[UsersInfo.findIndex((obj: any) => obj.id === user.uid)].username;

  const findUserFullName =
    UsersInfo[UsersInfo.findIndex((obj: any) => obj.id === user.uid)].full_name;

  useEffect(() => {
    if (First) {
      findUserUID.audioTrack.setEnabled(enableMicrophone);
    }
  }, [First, enableMicrophone]);

  useEffect(() => {
    if (callType) {
      if (
        First &&
        JSON.parse(window.localStorage.getItem("user") as string).id ===
          user.uid
      ) {
        findUserUID?.videoTrack.setEnabled(enableCamera);
        findUserUID?.videoTrack.play(ref.current);
        if (enableCamera && findUserUID?.videoTrack) {
          findUserUID?.videoTrack.setEnabled(enableCamera);
          findUserUID?.videoTrack.play(ref.current);
        } else {
          findUserUID?.videoTrack.setEnabled(enableCamera);
        }
      }
    } else {
      findUserUID?.videoTrack.setEnabled(enableCamera);
    }
  }, [First, enableCamera]);

  useEffect(() => {
    const checkUser = () => {
      console.log(users, "tata");

      if (audioRef.current) {
        // Check if audioRef is present
        if (
          JSON.parse(window.localStorage.getItem("user") as string).id !==
          user.uid
        ) {
          if (user.audioTrack) {
            user.audioTrack.play(audioRef.current);
          }
        }
      }

      if (refu) {
        console.log("ss");
        if (refu.current) {
          if (
            JSON.parse(window.localStorage.getItem("user") as string).id !==
            user.uid
          ) {
            if (user?.videoTrack?.play) {
              setPlayable(true);
              user?.videoTrack.play(refu.current);
              console.log(" playeable");
            } else {
              setPlayable(false);
              console.log("not playeable");

              if (UsersInfo && findUserImage) {
                setUserImage(findUserImage);
                setUserName(findUserUserName);
              }
            }
          }
        }
      } else {
        setPlayable(false);
      }
    };

    setInterval(checkUser, 5000);
  }, [users]);

  useEffect(() => {
    if (
      JSON.parse(window.localStorage.getItem("user") as string).id === user.uid
    ) {
      findUserUID.videoTrack?.play(ref.current);
    }
  }, []);

  useEffect(() => {}, [Playable, UsersInfo, findUserFullName]);

  return (
    <>
      {JSON.parse(window.localStorage.getItem("user") as string).id ===
      user.uid ? (
        <>
          <div
            ref={ref}
            style={{
              width: "100%",
              height: `${heightOfVideo}px`,
              display: enableCamera ? "block" : "none",
              border: "2px solid #3A3A3C ",
            }}
          >
            <div className={stylescall.video__player}>
              {
                JSON.parse(window.localStorage.getItem("user") as string)
                  .full_name
              }
            </div>
          </div>

          <div
            className="m-1 flex justify-center items-center  h-full border-black border-4 rounded-xl "
            style={{
              display: enableCamera ? "none" : "block",
              height: `${heightOfVideo}px`,
            }}
          >
            {JSON.parse(window.localStorage.getItem("user") as string)
              ?.profile_image?.url ? (
              <div className="flex justify-center items-center p-4 h-full flex-col">
                <Avatar
                  size={200}
                  src={
                    JSON.parse(window.localStorage.getItem("user") as string)
                      ?.profile_image?.url
                  }
                ></Avatar>
                <div className="font-bold text-2xl">
                  {
                    JSON.parse(window.localStorage.getItem("user") as string)
                      ?.full_name
                  }
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-center p-4 h-full flex-col">
                <Avatar size={200}>
                  {JSON.parse(
                    window.localStorage.getItem("user") as string
                  )?.full_name?.slice(0, 2)}
                </Avatar>
                <div className="font-bold text-2xl">
                  {
                    JSON.parse(window.localStorage.getItem("user") as string)
                      ?.full_name
                  }
                </div>
              </div>
            )}
          </div>
          <audio ref={audioRef} autoPlay />
        </>
      ) : (
        <>
          {Playable ? (
            <div
              ref={refu}
              style={{
                width: "100%",
                height: `${heightOfVideo}px`,
                border: "2px solid #3A3A3C ",
              }}
            >
              <div className={stylescall.video__player}>{findUserFullName}</div>
              <audio ref={audioRef} autoPlay />
            </div>
          ) : (
            <>
              <div
                ref={refu}
                style={{
                  width: "100%",
                  height: `${heightOfVideo}px`,
                  border: "2px solid #3A3A3C ",
                  display: Playable ? "block" : "none",
                }}
              ></div>
              <div
                className="m-1 flex justify-center items-center h-full border-black border-4 rounded-xl "
                style={{
                  display: Playable ? "none" : "block",
                  height: `${heightOfVideo}px`,
                }}
              >
                {UserImage || findUserFullName ? (
                  <>
                    {UserImage ? (
                      <div className="flex justify-center items-center p-4 h-full flex-col">
                        <Avatar size={200} src={UserImage}></Avatar>
                        <div className="font-bold text-2xl">
                          {findUserFullName}
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-center items-center p-4 h-full flex-col">
                        <Avatar size={200}>
                          {findUserFullName.slice(0, 2)}
                        </Avatar>
                        <div className="font-bold text-2xl">
                          {findUserFullName}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <audio ref={audioRef} autoPlay />
            </>
          )}
        </>
      )}
    </>
  );
};

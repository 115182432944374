import React, { useContext, useEffect, useState } from "react";
import spacesStyles from "../../../styles/pages/spaces.module.scss";
import { FetchSpaces } from "../../../services/spaces";
import { useQuery } from "react-query";
import { Dropdown, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import AddSpace from "../addSpace";
import { FetchGroups } from "../../../services/groups";
import ManageSpaces from "../manageSpaces";
import SortingManually from "./sortingManually";
import { MessageContext } from "../../../App";

const SpacesCon = ({ setSpace, data, refetch }: any) => {
  const [Selected, setSelected] = useState(0);

  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, []);

  type ISortType = "recent" | "a-z" | "z-a" | "manually";
  const [sortType, setSortTypeStorage] = useState<ISortType>(
    (window.localStorage.getItem("sortType") as ISortType) ?? "recent"
  );
  const [ids, setIdsStorage] = useState<Array<number>>(
    JSON.parse(window.localStorage.getItem("sortedItems") as string) ?? []
  );
  const setSortType = (e: ISortType) => {
    setSortTypeStorage(e);
    window.localStorage.setItem("sortType", e);
  };
  const setIds = (e: Array<number>) => {
    setIdsStorage(e);
    window.localStorage.setItem("sortedItems", JSON.stringify(e));
  };
  const [spacesData, setSpacesData] = useState<any>([]);

  const [newMessage,SetNewMessage] = useContext<any>(MessageContext);

  const sorting = (type: ISortType, propIds: Array<number> = ids) => {
    if (data) {
      const newArr = [...data?.json];
      if (type === "a-z") {
        newArr.sort((a: any, b: any) => a.name.localeCompare(b.name));
      } else if (type === "z-a") {
        newArr.sort((a: any, b: any) => b.name.localeCompare(a.name));
      } else if (type === "manually") {
        newArr.sort(
          (a: any, b: any) => propIds.indexOf(a.id) - propIds.indexOf(b.id)
        );
      } else if (type === "recent") {
      }
      setSpacesData([...newArr]);
    }
  };
  useEffect(() => {
    sorting(sortType);
  }, [data]);

  const groups = useQuery(
    ["groups", 0, ""],
    async () => FetchGroups(1, -1, ""),
    {
      cacheTime: 0,
      staleTime: 10000,
    }
  );

  const OpenAddSpace = () => {
    const createModal = Modal.info({
      className: "addspaceModal",
      title: "Create new space",
      width: "40%",
      autoFocusButton: null,
      centered: true,
      icon: <></>,
      content: (
        <AddSpace
          Groups={groups?.data?.json}
          onFinish={() => {
            refetch();
            groups.refetch();
          }}
        />
      ),
      footer: [],
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
    });
  };

  const openManageSpaces = () => {
    const createModal = Modal.info({
      className: "addspaceModal",
      title: "Manage spaces",
      width: "40%",
      centered: true,
      autoFocusButton: null,
      icon: <></>,
      content: (
        <ManageSpaces
          Groups={groups?.data?.json}
          onFinish={() => refetch()}
          Spaces={data}
          setSpace={setSpace}
        />
      ),
      footer: [],
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      afterClose() {
        refetch();
      },
      maskClosable: true,
      closable: true,
    });
  };

  useEffect(() => {
    setSpace(Selected);
  }, [Selected]);

  const openSortingManuallyModal = () => {
    Modal.info({
      className: "addspaceModal",
      title: "Sorting spaces manually",
      width: "40%",
      centered: true,
      autoFocusButton: null,
      icon: <></>,
      content: (
        <SortingManually
          spaces={spacesData}
          setIds={setIds}
          onSubmit={(value: any) => {
            setIds(value);
            setSortType("manually");
            sorting("manually", value);
            Modal.destroyAll();
          }}
        />
      ),
      footer: [],
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
    });
  };

  return (
    <div className={spacesStyles.spaces}>
      <div className={spacesStyles.spacesHeader}>
        <h1 className={spacesStyles.title}>Spaces</h1>
        <Dropdown
          trigger={["click"]}
          menu={{
            items: [
              {
                key: "recent",
                label: "Most recent",
                icon: (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.9767 19.5C19.4017 17.8876 21 15.1305 21 12C21 7.02944 16.9706 3 12 3C11.3126 3 10.6432 3.07706 10 3.22302M16.9767 19.5V16M16.9767 19.5H20.5M7 4.51555C4.58803 6.13007 3 8.87958 3 12C3 16.9706 7.02944 21 12 21C12.6874 21 13.3568 20.9229 14 20.777M7 4.51555V8M7 4.51555H3.5"
                      stroke="#48484A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ),
              },
              {
                key: "a-z",
                label: "Name ascending A-Z",
                icon: (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 14H8.42109C9.35119 14 9.81624 14 9.94012 14.2801C10.064 14.5603 9.74755 14.8963 9.11466 15.5684L5.47691 19.4316C4.84402 20.1037 4.52757 20.4397 4.65145 20.7199C4.77533 21 5.24038 21 6.17048 21H10"
                      stroke="#48484A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 9L6.10557 4.30527C6.49585 3.43509 6.69098 3 7 3C7.30902 3 7.50415 3.43509 7.89443 4.30527L10 9"
                      stroke="#48484A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.5 20V4M17.5 20C16.7998 20 15.4915 18.0057 15 17.5M17.5 20C18.2002 20 19.5085 18.0057 20 17.5"
                      stroke="#48484A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ),
              },
              {
                key: "z-a",
                label: "Name ascending Z-A",
                icon: (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 3H8.42109C9.35119 3 9.81624 3 9.94012 3.28013C10.064 3.56026 9.74755 3.89632 9.11466 4.56842L5.47691 8.43158C4.84402 9.10368 4.52757 9.43974 4.65145 9.71987C4.77533 10 5.24038 10 6.17048 10H10"
                      stroke="#48484A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 21L6.10557 16.3053C6.49585 15.4351 6.69098 15 7 15C7.30902 15 7.50415 15.4351 7.89443 16.3053L10 21"
                      stroke="#48484A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.5 20V4M17.5 20C16.7998 20 15.4915 18.0057 15 17.5M17.5 20C18.2002 20 19.5085 18.0057 20 17.5"
                      stroke="#48484A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ),
              },
              {
                key: "manually",
                label: "Sort manually",
                icon: (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1015 8.41889L12.3128 11.1048L19.0709 4.49666C19.7408 3.84161 20.827 3.84161 21.4968 4.49666C22.1668 5.15171 22.1668 6.21377 21.4968 6.86881L17.8579 10.4271L19.5979 12.7758C20.7341 14.279 21.3023 15.0306 21.491 15.7668C21.8029 16.9827 21.6665 18.2178 20.6452 19.2166C19.9337 19.9122 19.2806 20.207 17.5707 21.0429C16.4858 21.5733 15.9433 21.8386 15.4001 21.9372C14.5058 22.0997 13.5814 21.953 12.7855 21.5223C12.3021 21.2608 11.8733 20.8415 11.0155 20.0027L10.2885 19.2918C9.14814 18.1768 8.57798 17.6193 8.27962 16.9184C8.22612 16.7927 8.1795 16.6643 8.13996 16.5337C7.91949 15.8058 8.00247 15.0215 8.16841 13.453L8.70562 8.37521C8.78817 7.59492 9.41589 6.97646 10.2132 6.88983C11.1716 6.78571 12.0275 7.4788 12.1015 8.41889Z"
                      stroke="#48484A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.06854 3.52639C9.06854 4.37034 9.72762 5.0545 10.5406 5.0545C11.3536 5.0545 12.0127 4.37034 12.0127 3.52639C12.0127 2.68244 11.3536 1.99829 10.5406 1.99829C9.72762 1.99829 9.06854 2.68244 9.06854 3.52639ZM9.06854 3.52639H8.4171L7.63742 3.59933M3.50971 9.00634C4.35654 8.99247 5.00125 9.6541 5.01466 10.4733C5.02807 11.2925 4.3979 11.9975 3.55107 12.0113C2.70425 12.0252 2.0141 11.3339 2.00068 10.5147C1.98727 9.69554 2.66289 9.02021 3.50971 9.00634ZM3.50971 9.00634L3.60041 7.66061M5.31559 4.67407L4.98394 4.9839L4.69088 5.2996"
                      stroke="#48484A"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ),
                onClick(info) {
                  openSortingManuallyModal();
                },
              },
            ],
            selectable: true,
            defaultSelectedKeys: [sortType],
            selectedKeys: [sortType],
            onSelect(info) {
              if ((info.key as ISortType) === "manually") {
              } else {
                setSortType(info.key as ISortType);
                sorting(info.key as ISortType);
              }
            },
          }}
        >
          <button className={spacesStyles.sortbyBtn}>
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 6.5L9.33333 6.50005"
                stroke="#393E46"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2 10.5H6.66667"
                stroke="#393E46"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2 2.5H12.6667"
                stroke="#393E46"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.3333 14.5V6.5M12.3333 14.5C11.8665 14.5 10.9943 13.1705 10.6667 12.8333M12.3333 14.5C12.8001 14.5 13.6723 13.1705 14 12.8333"
                stroke="#393E46"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Sort by
          </button>
        </Dropdown>
      </div>

      <div className={spacesStyles.spacesBtns}>
        <button
          onClick={() => setSelected(0)}
          className={Selected === 0 ? spacesStyles.active : ""}
        >
          <div>
            <svg
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="44" height="44" rx="22" fill="#8E70E0" />
              <path
                d="M13.6667 27.0001C13.6667 25.7164 13.6667 25.0745 13.9556 24.603C14.1173 24.3392 14.3391 24.1173 14.6029 23.9557C15.0744 23.6667 15.7163 23.6667 17 23.6667C18.2837 23.6667 18.9255 23.6667 19.397 23.9557C19.6609 24.1173 19.8827 24.3392 20.0444 24.603C20.3333 25.0745 20.3333 25.7164 20.3333 27.0001C20.3333 28.2837 20.3333 28.9257 20.0444 29.3972C19.8827 29.661 19.6609 29.8828 19.397 30.0445C18.9255 30.3334 18.2837 30.3334 17 30.3334C15.7163 30.3334 15.0744 30.3334 14.6029 30.0445C14.3391 29.8828 14.1173 29.661 13.9556 29.3972C13.6667 28.9257 13.6667 28.2837 13.6667 27.0001Z"
                stroke="white"
                stroke-width="1.25"
              />
              <path
                d="M23.6667 27.0001C23.6667 25.7164 23.6667 25.0745 23.9556 24.603C24.1172 24.3392 24.3391 24.1173 24.6029 23.9557C25.0744 23.6667 25.7163 23.6667 27 23.6667C28.2837 23.6667 28.9256 23.6667 29.3971 23.9557C29.6609 24.1173 29.8827 24.3392 30.0444 24.603C30.3333 25.0745 30.3333 25.7164 30.3333 27.0001C30.3333 28.2837 30.3333 28.9257 30.0444 29.3972C29.8827 29.661 29.6609 29.8828 29.3971 30.0445C28.9256 30.3334 28.2837 30.3334 27 30.3334C25.7163 30.3334 25.0744 30.3334 24.6029 30.0445C24.3391 29.8828 24.1172 29.661 23.9556 29.3972C23.6667 28.9257 23.6667 28.2837 23.6667 27.0001Z"
                stroke="white"
                stroke-width="1.25"
              />
              <path
                d="M13.6667 17.0001C13.6667 15.7164 13.6667 15.0745 13.9556 14.603C14.1173 14.3392 14.3391 14.1174 14.6029 13.9557C15.0744 13.6667 15.7163 13.6667 17 13.6667C18.2837 13.6667 18.9255 13.6667 19.397 13.9557C19.6609 14.1174 19.8827 14.3392 20.0444 14.603C20.3333 15.0745 20.3333 15.7164 20.3333 17.0001C20.3333 18.2838 20.3333 18.9256 20.0444 19.3971C19.8827 19.661 19.6609 19.8828 19.397 20.0445C18.9255 20.3334 18.2837 20.3334 17 20.3334C15.7163 20.3334 15.0744 20.3334 14.6029 20.0445C14.3391 19.8828 14.1173 19.661 13.9556 19.3971C13.6667 18.9256 13.6667 18.2838 13.6667 17.0001Z"
                stroke="white"
                stroke-width="1.25"
              />
              <path
                d="M23.6667 17.0001C23.6667 15.7164 23.6667 15.0745 23.9556 14.603C24.1172 14.3392 24.3391 14.1174 24.6029 13.9557C25.0744 13.6667 25.7163 13.6667 27 13.6667C28.2837 13.6667 28.9256 13.6667 29.3971 13.9557C29.6609 14.1174 29.8827 14.3392 30.0444 14.603C30.3333 15.0745 30.3333 15.7164 30.3333 17.0001C30.3333 18.2838 30.3333 18.9256 30.0444 19.3971C29.8827 19.661 29.6609 19.8828 29.3971 20.0445C28.9256 20.3334 28.2837 20.3334 27 20.3334C25.7163 20.3334 25.0744 20.3334 24.6029 20.0445C24.3391 19.8828 24.1172 19.661 23.9556 19.3971C23.6667 18.9256 23.6667 18.2838 23.6667 17.0001Z"
                stroke="white"
                stroke-width="1.25"
              />
            </svg>
            All
          </div>
        </button>
        <button
          onClick={() => setSelected(-1)}
          className={Selected === -1 ? spacesStyles.active : ""}
        >
          <div>
            <svg
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="44" height="44" rx="22" fill="#7070E0" />
              <path
                d="M21.1667 16.5833H29.5"
                stroke="white"
                stroke-width="1.25"
                stroke-linecap="round"
              />
              <path
                d="M16.5 26.0771C17.3889 26.6724 17.8333 26.9701 17.8333 27.4166C17.8333 27.8631 17.3889 28.1608 16.5 28.7561C15.6111 29.3514 15.1667 29.649 14.8333 29.4258C14.5 29.2026 14.5 28.6073 14.5 27.4166C14.5 26.2259 14.5 25.6306 14.8333 25.4073C15.1667 25.1842 15.6111 25.4818 16.5 26.0771Z"
                stroke="white"
                stroke-width="1.25"
                stroke-linecap="round"
              />
              <path
                d="M16.5 15.2439C17.3889 15.8392 17.8333 16.1368 17.8333 16.5833C17.8333 17.0298 17.3889 17.3275 16.5 17.9228C15.6111 18.5181 15.1667 18.8158 14.8333 18.5925C14.5 18.3693 14.5 17.774 14.5 16.5833C14.5 15.3927 14.5 14.7974 14.8333 14.5741C15.1667 14.3509 15.6111 14.6485 16.5 15.2439Z"
                stroke="white"
                stroke-width="1.25"
                stroke-linecap="round"
              />
              <path
                d="M21.1667 22H29.5"
                stroke="white"
                stroke-width="1.25"
                stroke-linecap="round"
              />
              <path
                d="M21.1667 27.4167H29.5"
                stroke="white"
                stroke-width="1.25"
                stroke-linecap="round"
              />
            </svg>
            Unlisted
          </div>
        </button>
        {spacesData?.map((space: any) => (
          <button
            onClick={() => setSelected(space.id)}
            className={Selected === space.id ? spacesStyles.active : ""}
          >
            <div>
              <img src={space.icon.url} alt="" />
              <span className={spacesStyles.name}>{space.name}</span>
            </div>
            {space.unread_messages_count &&
            space.unread_messages_count !== 0 ? (
              <span className={spacesStyles.counter}>
                {space.unread_messages_count}
              </span>
            ) : (
              ""
            )}
          </button>
        ))}
      </div>
      {(UserLoc?.role === "owner" ||
        UserLoc?.role === "su" ||
        UserLoc?.role === "admin") && (
        <div className={spacesStyles.spacesManagment}>
          <button onClick={() => OpenAddSpace()}>
            <div>
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="1"
                  y="1"
                  width="40"
                  height="40"
                  rx="20"
                  fill="#F7F8F9"
                />
                <rect
                  x="1"
                  y="1"
                  width="40"
                  height="40"
                  rx="20"
                  stroke="#E7EAEE"
                />
                <path
                  d="M21 14.3333V27.6666"
                  stroke="#393E46"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.3333 21H27.6667"
                  stroke="#393E46"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Create new space
            </div>
          </button>
          <button onClick={() => openManageSpaces()}>
            <div>
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="1"
                  y="1"
                  width="40"
                  height="40"
                  rx="20"
                  fill="#F7F8F9"
                />
                <rect
                  x="1"
                  y="1"
                  width="40"
                  height="40"
                  rx="20"
                  stroke="#E7EAEE"
                />
                <path
                  d="M23.9167 20.9999C23.9167 22.6108 22.6108 23.9166 21 23.9166C19.3892 23.9166 18.0833 22.6108 18.0833 20.9999C18.0833 19.3891 19.3892 18.0833 21 18.0833C22.6108 18.0833 23.9167 19.3891 23.9167 20.9999Z"
                  stroke="#393E46"
                  stroke-width="1.25"
                />
                <path
                  d="M28.3255 18.6267C28.9974 19.7848 29.3333 20.3638 29.3333 21C29.3333 21.6362 28.9974 22.2152 28.3255 23.3733L26.7224 26.1365C26.0532 27.29 25.7186 27.8668 25.1682 28.1834C24.618 28.5 23.9502 28.5 22.6144 28.5H19.3856C18.0498 28.5 17.382 28.5 16.8317 28.1834C16.2814 27.8668 15.9468 27.29 15.2776 26.1365L13.6745 23.3733C13.0026 22.2152 12.6667 21.6362 12.6667 21C12.6667 20.3638 13.0026 19.7848 13.6745 18.6267L15.2776 15.8635C15.9468 14.71 16.2814 14.1332 16.8317 13.8166C17.382 13.5 18.0498 13.5 19.3856 13.5H22.6144C23.9502 13.5 24.618 13.5 25.1682 13.8166C25.7186 14.1332 26.0532 14.71 26.7224 15.8635L28.3255 18.6267Z"
                  stroke="#393E46"
                  stroke-width="1.25"
                />
              </svg>
              Mange spaces
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default SpacesCon;

import { Col, Input, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import UsersCon from "../../components/users/UserCon";

import SpacesCon from "../../components/spaces/spacesCon";
import Groups from "../../components/room/UsersCon";
import Messages from "../../components/spaces/messages";
import { useQuery } from "react-query";
import { FetchGroups } from "../../services/groups";
import { FetchContacts } from "../../services/contacts";
import { MessageContext } from "../../App";
import Users from "../../components/users/Profile";
import CreateNewUser from "../../components/users/CreateNewUser";

import type { TabsProps } from "antd";
import { Tabs, theme } from "antd";
import StickyBox from "react-sticky-box";
import { genStyleHooks } from "antd/es/theme/internal";
import usersStyles from "../../styles/pages/profile.module.scss";
import EditUser from "../../components/users/editUser";
import EditUserPermissions from "../../components/users/editUserPermissions";

const { Search } = Input;

const UsersMangment: React.FC = () => {
  const [RoomId, setRoomId] = useState(0);
  const [SubRoomId, setSubRoomId] = useState(0);
  const [GroupsLoading, setGroupsLoading] = useState(true);
  const [UserEdit, setUserEdit] = useState<any>({});
  const [UserPermissionId, setUserPermissionId] = useState<any>({});
  const [IsAddUaserActive, seIsAddUaserActive] = useState<boolean>(false);
  const [SearchText, setSearch] = useState("");
  const [SearchContact, setSearchContact] = useState("");
  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, []);

  useEffect(() => {
    if (IsAddUaserActive) {
      setUserPermissionId({});
      setUserEdit({});
    }
  }, [IsAddUaserActive]);
  useEffect(() => {
    if (UserPermissionId?.id) {
      seIsAddUaserActive(false);
      setUserEdit({});
    }
  }, [UserPermissionId]);
  useEffect(() => {
    if (UserEdit?.id) {
      seIsAddUaserActive(false);
      setUserPermissionId({});
    }
  }, [UserEdit]);
  const { data, status, isLoading, refetch } = useQuery(
    ["Contacts", UserLoc?.id],
    async () => FetchContacts(0, UserLoc?.id),
    {
      cacheTime: 0,
      staleTime: 10000,
    }
  );

  const  [newMessage,SetNewMessage] = useContext<any>(MessageContext);
  useEffect(() => {
    if (newMessage && Object.keys(newMessage).length > 0) {
      refetch();
    }
  }, [newMessage]);

  const handleState = () => {
    seIsAddUaserActive(!IsAddUaserActive);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "All",
      children: (
        <UsersCon
          data={data?.json.filter((contactUser: any) =>
            contactUser?.contact?.username
              .toLowerCase()
              .includes(SearchContact.toLowerCase())
          )}
          setUser={(data: any) => {
            setUserPermissionId(data);
          }}
          setUserEdit={(data: any) => {
            setUserEdit(data);
          }}
          setLoading={(status: boolean) => setGroupsLoading(status)}
          Loading={GroupsLoading}
          user={UserPermissionId}
          change={handleState}
        />
      ),
    },
    {
      ...(data?.json &&
        data?.json?.filter((item: any) => item?.contact?.role === "su").length >
          0 && {
          key: "su",
          label: "super",
          children: (
            <UsersCon
              data={data?.json
                ?.filter((item: any) => item?.contact?.role === "su")
                .filter((contactUser: any) =>
                  contactUser?.contact?.username
                    .toLowerCase()
                    .includes(SearchContact.toLowerCase())
                )}
              setUser={(data: any) => {
                setUserPermissionId(data);
              }}
              setUserEdit={(data: any) => {
                setUserEdit(data);
              }}
              setLoading={(status: boolean) => setGroupsLoading(status)}
              Loading={GroupsLoading}
              user={UserPermissionId}
              change={handleState}
            />
          ),
        }),
    },
    {
      ...(data?.json &&
        data?.json?.filter((item: any) => item?.contact?.role === "admin")
          .length > 0 && {
          key: "admin",
          label: "Admins",
          closeIcon: false,
          children: (
            <UsersCon
              data={data?.json
                ?.filter((item: any) => item?.contact?.role === "admin")
                .filter((contactUser: any) =>
                  contactUser?.contact?.username
                    .toLowerCase()
                    .includes(SearchContact.toLowerCase())
                )}
              setUser={(data: any) => {
                setUserPermissionId(data);
              }}
              setUserEdit={(data: any) => {
                setUserEdit(data);
              }}
              setLoading={(status: boolean) => setGroupsLoading(status)}
              Loading={GroupsLoading}
              user={UserPermissionId}
              change={handleState}
            />
          ),
        }),
    },

    {
      ...(data?.json &&
        data?.json?.filter((item: any) => item?.contact?.role === "staff")
          .length > 0 && {
          key: "internal",
          label: "Internal",
          children: (
            <UsersCon
              data={data?.json
                ?.filter((item: any) => item?.contact?.role === "staff")
                .filter((contactUser: any) =>
                  contactUser?.contact?.username
                    .toLowerCase()
                    .includes(SearchContact.toLowerCase())
                )}
              setUser={(data: any) => {
                setUserPermissionId(data);
              }}
              setUserEdit={(data: any) => {
                setUserEdit(data);
              }}
              setLoading={(status: boolean) => setGroupsLoading(status)}
              Loading={GroupsLoading}
              user={UserPermissionId}
              change={handleState}
            />
          ),
        }),
    },
    {
      ...(data?.json &&
        data?.json?.filter((item: any) => item?.contact?.role === "client")
          .length > 0 && {
          key: "external",
          label: "External",
          children: (
            <UsersCon
              data={data?.json
                ?.filter((item: any) => item?.contact?.role === "client")
                .filter((contactUser: any) =>
                  contactUser?.contact?.username
                    .toLowerCase()
                    .includes(SearchContact.toLowerCase())
                )}
              setUser={(data: any) => {
                setUserPermissionId(data);
              }}
              setUserEdit={(data: any) => {
                setUserEdit(data);
              }}
              setLoading={(status: boolean) => setGroupsLoading(status)}
              Loading={GroupsLoading}
              user={UserPermissionId}
              change={handleState}
            />
          ),
        }),
    },
  ];
  // const items: TabsProps["items"] = [
  //   {
  //     ...(data?.json &&
  //       data?.json?.filter((item: any) => item?.contact?.role === "op").length >
  //         0 && {
  //         key: "op",
  //         label: "OP",
  //         children: (
  //           <div className="chatUsers">
  //             {data?.json
  //               ?.filter((item: any) => item?.contact?.role === "op")
  //               .map((user: any) => (
  //                 <div className={"userCard"}>
  //                   <div style={{ display: "flex", alignItems: "center" }}>
  //                     <div className="cardImgCon">
  //                       <div className="imgPlaceHolder">
  //                         <img src={user?.contact?.profile_image?.url} alt="" />
  //                       </div>
  //                     </div>
  //                     <div className="cardText">
  //                       <div className="cardTitle">
  //                         {user?.contact?.full_name}
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <Button
  //                     onClick={(e) => OpenEditPopUp(user)}
  //                     className="editButton"
  //                     loading={CanEdit === user?.contact?.id}
  //                   >
  //                     <img src="/images/editIconLight.svg" alt="" />
  //                     Edit
  //                   </Button>
  //                 </div>
  //               ))}
  //           </div>
  //         ),
  //       }),
  //   },

  //   {
  //     ...(data?.json &&
  //       data?.json?.filter((item: any) => item?.contact?.role === "owner")
  //         .length > 0 && {
  //         key: "owner",
  //         label: "Owners",
  //         children: (
  //           <div className="chatUsers">
  //             {data?.json
  //               ?.filter((item: any) => item?.contact?.role === "owner")
  //               .map((user: any) => (
  //                 <div className={"userCard"}>
  //                   <div style={{ display: "flex", alignItems: "center" }}>
  //                     <div className="cardImgCon">
  //                       <div className="imgPlaceHolder">
  //                         <img src={user?.contact?.profile_image?.url} alt="" />
  //                       </div>
  //                     </div>
  //                     <div className="cardText">
  //                       <div className="cardTitle">
  //                         {user?.contact?.full_name}
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <Button
  //                     onClick={(e) => OpenEditPopUp(user)}
  //                     className="editButton"
  //                     loading={CanEdit === user?.contact?.id}
  //                   >
  //                     <img src="/images/editIconLight.svg" alt="" />
  //                     Edit
  //                   </Button>
  //                 </div>
  //               ))}
  //           </div>
  //         ),
  //       }),
  //   },
  // ];

  return (
    <div style={{ height: "100%" }} className={usersStyles.manageUserCon}>
      <Row style={{ height: "100%" }}>
        <Col md={7}>
          <div className={usersStyles.groups}>
            <div className={usersStyles.groupsHeader}>
              <h5 className={usersStyles.title}>Manage Users</h5>
              <button className={usersStyles.red} onClick={() => handleState()}>
                <>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 8H12"
                      stroke="url(#paint0_linear_2745_10703)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 12V4"
                      stroke="url(#paint1_linear_2745_10703)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2745_10703"
                        x1="12.36"
                        y1="7.93"
                        x2="12.104"
                        y2="10.1255"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BF4034" />
                        <stop offset="1" stop-color="#9B1724" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_2745_10703"
                        x1="9.045"
                        y1="3.44"
                        x2="6.70132"
                        y2="3.75407"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#BF4034" />
                        <stop offset="1" stop-color="#9B1724" />
                      </linearGradient>
                    </defs>
                  </svg>
                  New user
                </>
              </button>
            </div>
            <div className={usersStyles.searchCon}>
              <Search
                placeholder="Search..."
                onChange={(e: any) =>
                  e.target.value === "" && setSearchContact("")
                }
                onSearch={(e: any) => setSearchContact(e)}
                prefix={<img src="/images/search.png" />}
              />
            </div>
            {data?.json?.length === 0 ? (
              <div className={usersStyles.emptypage}>
                <img src="/images/window.png" alt="" />
                <h4>No contacts available</h4>
                <p>You don't have any contacts.</p>
                <button onClick={handleState}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00002 2.78882V13.211"
                      stroke="#636366"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.7889 8H13.2111"
                      stroke="#636366"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Create New User
                </button>
              </div>
            ) : (
              <Tabs
                defaultActiveKey="1"
                items={items}
                className={usersStyles.user__tabs}
              />
            )}
          </div>
        </Col>
        <Col md={17}>
          {IsAddUaserActive ? (
            <CreateNewUser
              refetchMain={() => {
                refetch();
              }}
              onCancel={(x: any) => seIsAddUaserActive(false)}
            />
          ) : (
            <></>
          )}
          {UserPermissionId?.id && (
            <EditUserPermissions
              setUser={(data: any) => {
                setUserPermissionId(data);
              }}
              currentUser={UserPermissionId}
              data={data?.json.filter((contactUser: any) =>
                contactUser?.contact?.username
                  .toLowerCase()
                  .includes(SearchText.toLowerCase())
              )}
              refetch={() => refetch()}
              setSearch={(text: any) => setSearch(text)}
            />
          )}
          {UserEdit?.id && (
            <EditUser
              key={UserEdit?.id}
              user={UserEdit}
              refetch={() => {
                refetch();
                setUserEdit({});
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UsersMangment;

import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "../../index.css";

// import required modules
import { Pagination } from "swiper/modules";

import authStyles from "../../styles/pages/auth.module.scss";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  type FormProps,
  Input,
  Space,
  Typography,
  type GetProp,
} from "antd";

import { notification } from "antd";
import { login } from "../../actions/auth";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const Login: React.FC = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [clientReady, setClientReady] = useState<boolean>(false);

  // To disable submit button at the beginning.
  useEffect(() => {
    setClientReady(true);
    console.log("here welcome");
  }, []);

  const onFinish = (values: any) => {
    console.log("Finish:", values);
  };

  const onChange: GetProp<typeof Input.OTP, "onChange"> = (text) => {
    console.log("onChange:", text);
  };

  const sharedProps = {
    onChange,
  };

  const history = useNavigate();
  const [Loading, setLoading] = useState(false);
  const submitLogin = (e: any) => {
    setLoading(true);
    login(e.username, e.password)
      .then(() => {
        history("/spaces");
        navigate(0);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error?.response);
        console.log(error?.message);
        notification.open({
          message: "Error",
          description: error.message,
        });
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history("/spaces");
    }
  }, []);

  return (
    <div className={authStyles.auth__container}>
      <div className="wrapper__flex">
        <div className={authStyles.part}>
          <Swiper
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
            enabled={false}
          >
            <SwiperSlide>
              <Form
                form={form}
                name="horizontal_login"
                layout="vertical"
                onFinish={(e) => submitLogin(e)}
              >
                <h2>Welcome back</h2>
                <p>Log in to your account to continue.</p>
                <div className="m-2"></div>

                <Form.Item<FieldType>
                  label="User name"
                  name="username"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Username"
                  />
                </Form.Item>
                <Form.Item<FieldType>
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>

                <div className="m-2"></div>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="main__btn"
                      disabled={
                        !clientReady ||
                        !form.isFieldsTouched(true) ||
                        !!form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    >
                      Log in
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </SwiperSlide>
            <SwiperSlide>
              <Form
                form={form}
                name="horizontal_login"
                layout="vertical"
                onFinish={(e) => submitLogin(e)}
              >
                <div className="otp__wrapper">
                  <img
                    src="/auth/shield-security.png"
                    alt=""
                    className={authStyles.img__otp}
                  />
                  <h2>OTP verification</h2>
                  <p>Enter the OTP to access the account</p>
                  <div className="m-4"></div>
                  <Space direction="vertical">
                    <Input.OTP
                      formatter={(str) => str.toUpperCase()}
                      {...sharedProps}
                    />
                  </Space>
                  <div className="m-4"></div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="main__btn"
                    disabled={
                      !clientReady ||
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    Continue
                  </Button>
                </div>
              </Form>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className={authStyles.part}>
          <Swiper
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src="/Banner.png" alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <img src="/Banner.png" alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Login;

import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";

import spacesStyles from "../../styles/pages/spaces.module.scss";
import SpacesCon from "../../components/spaces/spacesCon";
import Groups from "../../components/room/UsersCon";
import Messages from "../../components/spaces/messages";
import { useQuery } from "react-query";
import { FetchGroups } from "../../services/groups";
import { FetchUsersMessages, MakeSeen } from "../../services/chats";
import UsersCon from "../../components/room/UsersCon";
import { LastContext, MessageContext, NotificationContext } from "../../App";
import { database } from "../../firebaseConfig";
import { ref, set, get, child } from "firebase/database";
import moment from "moment";

const MessagesPage = () => {
  const [RoomId, setRoomId] = useState(0);
  const [SubRoomId, setSubRoomId] = useState(0);
  const [SearchText, setSearch] = useState<any>("");
  const [GroupsLoading, setGroupsLoading] = useState(true);
  const [user, setUser] = useState<any>({});
  const [UserList, setUserList] = useState<any>([]);
  const [Last, setLast] = useContext<any>(LastContext);
  const [NotificationRoom, SetNotificationRoom] =
    useContext<any>(NotificationContext);
  const [newMessage, SetNewMessage] = useContext<any>(MessageContext);
  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, []);
  const { data, status, isLoading, refetch } = useQuery(
    ["UsersChats", SearchText],
    async () => FetchUsersMessages(1, SearchText),
    {
      cacheTime: 0,
      staleTime: 10000,
    }
  );
  useEffect(() => {
    setUserList(data?.json);
  }, [data]);
  useEffect(() => {
    if (Object.keys(Last).length > 0) {
      console.log("hello1");
      let newUserList = UserList;
      let seletedIndex = newUserList.findIndex(
        (item: any) =>
          item?.id === Last?.roomId || item?.staff_chat?.id === Last?.roomId
      );
      newUserList[seletedIndex] = {
        ...newUserList[seletedIndex],
        last_message: {
          ...newUserList[seletedIndex]?.last_message,
          content: "You: " + Last.messsage,
          created_at: new Date().toISOString(),
        },
      };

      newUserList.unshift(newUserList.splice(seletedIndex, 1)[0]);
      setUserList([...newUserList]);
      console.log("hello2", newUserList);
    }
  }, [Last]);
  useEffect(() => {
    if (RoomId !== 0) {
      let newUserList = UserList;
      let seletedIndex = newUserList.findIndex(
        (item: any) => item?.id === RoomId
      );

      newUserList[seletedIndex] = {
        ...newUserList[seletedIndex],
        unread_messages_count: 0,
      };

      setUserList([...newUserList]);
    }
  }, [RoomId]);
  useEffect(() => {
    if (NotificationRoom?.chat_id) {
      let newid = NotificationRoom?.chat_id;
      setRoomId(newid);
      setUser(data?.json.find((x: any) => x.id === NotificationRoom?.chat_id));
    }
  }, [NotificationRoom]);

  useEffect(() => {
    localStorage.setItem("roomId", "0");
    let oldRoomId = "0";
    if (UserLoc?.id) {
      document.addEventListener("visibilitychange", function () {
        if (document.hidden) {
          if (UserLoc && UserLoc?.id)
            set(ref(database, UserLoc?.id?.toString()), {
              last_seen: moment.now(),
              presence: false,
            });
          oldRoomId = localStorage.getItem("roomId") as string;
          localStorage.setItem("roomId", "");
        } else {
          if (UserLoc && UserLoc?.id)
            set(ref(database, UserLoc?.id?.toString()), {
              last_seen: moment.now(),
              presence: true,
            });
          console.log("oldRoomId", oldRoomId);
          if (oldRoomId && oldRoomId !== null && oldRoomId !== "0") {
            localStorage.setItem("roomId", oldRoomId);
            MakeSeen(parseInt(oldRoomId));
          }
        }
      });
    }
  }, [UserLoc]);
  // useEffect(() => {
  //   if (SearchText !== "") setGroupsLoading(true);
  // }, [SearchText]);
  useEffect(() => {
    if (newMessage) {
      console.log("newMessage", newMessage);
      if (newMessage?.refetch === true) {
        refetch();
      } else {
        if (newMessage?.chat_type === 1) {
          let newUserList = UserList;
          let seletedIndex = newUserList.findIndex(
            (item: any) => item?.id === newMessage?.chat_id
          );
          newUserList[seletedIndex] = {
            ...newUserList[seletedIndex],
            last_message: {
              ...newUserList[seletedIndex]?.last_message,
              content: newMessage?.preview,
              created_at: newMessage?.created_at,
            },
            unread_messages_count:
              newUserList[seletedIndex]?.unread_messages_count + 1,
          };
          newUserList.unshift(newUserList.splice(seletedIndex, 1)[0]);
          setUserList([...newUserList]);
        }
      }
    }
  }, [newMessage]);
  useEffect(() => {
    console.log("RoomRoomchangedRoomId", RoomId);
  }, [RoomId]);
  return (
    <Row style={{ height: "100%" }}>
      <Col md={7} xxl={5}>
        <UsersCon
          key={JSON.stringify(UserList)}
          withLastMessage
          data={UserList}
          RoomId={RoomId}
          setRoomId={(Id: any) => {
            setRoomId(Id);
          }}
          setUser={(data: any) => {
            setUser(data);
          }}
          setSubRoomId={(Id: any) => setSubRoomId(Id)}
          setLoading={(status: boolean) => setGroupsLoading(status)}
          Loading={GroupsLoading}
          setSearch={(data: any) => {
            setSearch(data);
          }}
        />
      </Col>
      <Col md={17} xxl={19}>
        <Messages
          key={RoomId + " " + SubRoomId}
          group={false}
          RoomId={RoomId}
          SubRoomId={SubRoomId}
          user={user}
          setRoomId={(Id: any) => setRoomId(Id)}
          setUser={(data: any) => {
            setUser(data);
          }}
          setSubRoomId={(Id: any) => setSubRoomId(Id)}
          onFinish={() => console.log("test")}
        />
      </Col>
    </Row>
  );
};

export default MessagesPage;

import { Button, Checkbox, Input, Modal, notification, Tabs } from "antd";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import usersStyles from "./styles.module.scss";
import { UpdatePerm } from "../../../../services/groups";
import PermissionsList from "../permissionsList";
import { CloseOutlined } from "@ant-design/icons";

const { Search } = Input;

const ManageUsers = ({
  Contacts,
  setPerms,
  Perms,
  members,
  RoomId,
  setOpen,
}: any) => {
  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, []);
  const [CheckList, setCheckList] = useState<{
    internal: Array<string>;
    external: Array<string>;
  }>({
    internal: members
      ? members
          ?.filter((mem: any) => mem?.role !== "client")
          ?.map((newMembers: any) => newMembers?.id?.toString())
      : Perms?.filter((p: any) => p?.type === "internal")?.map((m: any) =>
          m?.id?.toString()
        ),
    external: members
      ? members
          ?.filter((mem: any) => mem?.role === "client")
          ?.map((newMembers: any) => newMembers?.id?.toString())
      : Perms?.filter((p: any) => p?.type === "external")?.map((m: any) =>
          m?.id?.toString()
        ),
  });
  const [LocPerms, setLocPerms] = useState<
    Array<{
      id: number;
      name: string;
      perm: Array<number>;
      type: "internal" | "external";
    }>
  >(RoomId ? [] : Perms);
  const [Loading, setLoading] = useState<any>(false);

  const scrollContainerRef = {
    internal: useRef<HTMLDivElement>(null),
    external: useRef<HTMLDivElement>(null),
  };

  let savedScrollTop: { internal: number; external: number } = {
    internal: 0,
    external: 0,
  };
  if (scrollContainerRef.internal.current) {
    savedScrollTop.internal = scrollContainerRef.internal.current.scrollTop;
  }
  if (scrollContainerRef.external.current) {
    savedScrollTop.external = scrollContainerRef.external.current.scrollTop;
  }

  useEffect(() => {
    if (scrollContainerRef.internal.current && savedScrollTop.internal) {
      scrollContainerRef.internal.current.scrollTop = savedScrollTop.internal;
    }
    if (scrollContainerRef.external.current && savedScrollTop.external) {
      scrollContainerRef.external.current.scrollTop = savedScrollTop.external;
    }
  });

  useEffect(() => {
    setPerms([...LocPerms]);
  }, [LocPerms]);

  const HandleSubmit = () => {
    setLoading(true);
    const list = [...CheckList.internal, ...CheckList.external];
    var form_data = new FormData();
    form_data.append("id", RoomId);
    LocPerms.map((per: any, index: any) => {
      if (list?.includes(per.id.toString())) {
        form_data.append(`user_ids[${index}][id]`, per.id);
        per.perm.map((perId: any, newIndex: any) => {
          form_data.append(
            `user_ids[${index}][permissions][${newIndex}][perm]`,
            perId
          );
          form_data.append(
            `user_ids[${index}][permissions][${newIndex}][allow]`,
            "1"
          );
        });
      }
    });
    UpdatePerm(form_data)
      .then((res: any) => {
        setLoading(false);
        notification.open({
          type: "success",
          message: "Group members updated successfully",
        });
        setOpen?.(false);
        Modal.destroyAll();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let newLocPerms = [];
    if (members && members?.length > 0) {
      newLocPerms = members.map((member: any) => {
        let permsUser = [];
        if (member?.props?.find((x: any) => x.perm === 1).allow === 1)
          permsUser.push(1);
        if (member?.props?.find((x: any) => x.perm === 2).allow === 1)
          permsUser.push(2);
        if (member?.props?.find((x: any) => x.perm === 3).allow === 1)
          permsUser.push(3);
        if (member?.props?.find((x: any) => x.perm === 4).allow === 1)
          permsUser.push(4);
        if (member?.props?.find((x: any) => x.perm === 5).allow === 1)
          permsUser.push(5);
        return {
          id: member.id,
          name: member.full_name,
          perm: permsUser,
          type: member.role !== "client" ? "internal" : "external",
        };
      });
      setLocPerms(newLocPerms);
    }
  }, [members]);

  const OpenPermissionPopUp = (user: any, type: "internal" | "external") => {
    Modal.info({
      className: "addspaceModal",
      title: "Select user role",
      width: "18rem",
      content: (
        <PermissionsList
          Permissions={
            members
              ? LocPerms?.find(
                  (x: any) => x?.id.toString() === user?.contact.id.toString()
                )?.perm
              : LocPerms.find((perm: any) => perm?.id === user?.contact?.id)
                  ?.perm
          }
          setRoles={(roles: any) => {
            console.log("roles", roles);
            setPerms(roles);
            if (roles.length > 0) {
              let newArr = LocPerms;
              if (
                newArr.filter((e: any) => e.id === user?.contact.id).length > 0
              )
                newArr.map((obj: any) => {
                  if (obj.id === user?.contact.id) obj.perm = roles;
                });
              else
                newArr.push({
                  id: user?.contact.id,
                  name: user?.contact?.full_name,
                  perm: roles,
                  type,
                });
              setLocPerms([...newArr]);
              setPerms([...newArr]);
            }
          }}
        />
      ),
      centered: false,
      okText: "Confirm",
      okButtonProps: {
        className: "createbtn",
        type: "primary",
      },
      icon: <></>,
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
      autoFocusButton: null,
    });
  };

  const View = memo(({ type }: { type: "internal" | "external" }) => {
    const [search, setSearch] = useState("");
    const handleCheckChange = useCallback(
      (e: any, user: any, type: "internal" | "external") => {
        if (e.target.checked) {
          setCheckList((prev) => ({
            ...prev,
            [type]: [...prev[type], user?.contact?.id.toString()],
          }));
          setLocPerms((prev) => [
            ...prev,
            {
              id: user?.contact?.id,
              name: user?.contact?.full_name,
              perm: [1],
              type,
            },
          ]);
        } else {
          setCheckList((prev) => {
            const i = prev[type].findIndex((i: any) => i === e.target.value);
            prev[type].splice(i, 1);
            return { ...prev };
          });
          setLocPerms((prev) => {
            const i = prev.findIndex(
              (x: any) => x.id.toString() === user?.contact?.id.toString()
            );
            prev.splice(i, 1);
            return [...prev];
          });
        }
      },
      []
    );
    const condition = (role: any) =>
      type === "internal"
        ? role !== "client"
        : type === "external" && role === "client";
    return (
      <div
        key={type}
        ref={scrollContainerRef[type]}
        className={usersStyles.usersView}
      >
        <div className={usersStyles.searchCon}>
          <Search
            placeholder="Search..."
            onChange={(e: any) => {
              setSearch(e.target.value);
            }}
            onSearch={(e: any) => setSearch(e)}
            prefix={<img src="/images/search.png" />}
          />
        </div>
        <Checkbox.Group
          value={CheckList[type]}
          defaultValue={
            members
              ? members?.map((newMembers: any) => newMembers?.id?.toString())
              : []
          }
        >
          {Contacts?.data &&
            Contacts?.data?.json
              ?.filter((user: any) => user?.contact?.id !== UserLoc?.id)
              ?.filter((user: any) => condition(user?.contact?.role))
              ?.filter((user: any) => user.contact?.full_name.includes(search))
              .map((user: any, index: number) => (
                <div key={index} className={usersStyles.userItem}>
                  <Checkbox
                    className={usersStyles.checkbox}
                    value={user?.contact?.id.toString()}
                    onChange={(e) => {
                      handleCheckChange(e, user, type);
                    }}
                  />
                  <div className={usersStyles.icon}>
                    <img
                      height={20}
                      src={user?.contact?.profile_image?.url}
                      alt=""
                    />
                  </div>
                  <div className={usersStyles.info}>
                    <div className={usersStyles.name}>
                      {user?.contact?.full_name}
                    </div>
                    <div className={usersStyles.role}>
                      {user?.contact?.role === "staff"
                        ? "internal"
                        : user?.contact?.role === "client"
                        ? "external"
                        : user?.contact?.role === "su"
                        ? "Super admin"
                        : user?.contact?.role === "op"
                        ? "Owner"
                        : user?.contact?.role}
                    </div>
                  </div>
                  {CheckList[type]?.includes?.(
                    user?.contact?.id.toString()
                  ) && (
                    <Button
                      onClick={(e) => OpenPermissionPopUp(user, type)}
                      type="text"
                      className={usersStyles.editButton}
                    >
                      {(!LocPerms.find((x: any) => x.id === user?.contact?.id)
                        ?.perm ||
                        LocPerms.find((x: any) => x.id === user?.contact?.id)
                          ?.perm.length === 0) &&
                        "SELECT USER ROLE"}
                      {LocPerms.find(
                        (x: any) => x.id === user?.contact?.id
                      )?.perm.includes(1) && (
                        <div className={usersStyles.roleItem}>
                          <img src="/images/read-only.svg" alt="" />
                          Read-only
                        </div>
                      )}
                      {LocPerms.find(
                        (x: any) => x.id === user?.contact?.id
                      )?.perm.includes(3) && (
                        <div className={usersStyles.roleItem}>
                          <img src="/images/hidden.svg" alt="" />
                          Hidden
                        </div>
                      )}
                      {LocPerms.find(
                        (x: any) => x.id === user?.contact?.id
                      )?.perm.includes(2) && (
                        <div className={usersStyles.roleItem}>
                          <img src="/images/read-write.svg" alt="" />
                          Read-write
                        </div>
                      )}
                    </Button>
                  )}
                </div>
              ))}
        </Checkbox.Group>
      </div>
    );
  });
  return (
    <>
      <div className={usersStyles.users + " spaceCreateForm"}>
        <Tabs
          className={usersStyles.tabs}
          defaultActiveKey="1"
          items={[
            {
              key: "1",
              label: "Internal",
              children: <View type="internal" />,
            },
            {
              key: "2",
              label: "External",
              children: <View type="external" />,
            },
          ]}
        />
        {RoomId ? (
          <div style={{ textAlign: "end" }}>
            <Button
              loading={Loading}
              className="createbtn"
              type="primary"
              onClick={() => HandleSubmit()}
            >
              Save changes
            </Button>
          </div>
        ) : (
          <div style={{ textAlign: "end" }}>
            <Button
              loading={Loading}
              className="createbtn"
              type="primary"
              onClick={() => setOpen?.(false)}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default ManageUsers;

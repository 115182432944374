import { useState } from "react";
import { Avatar, Button, Checkbox, Input } from "antd";
import profileStyles from "../../../../styles/pages/profile.module.scss";
import { StartCall } from "../../../../services/calls";
import VideoRoom from "../../../call/VideoRoom";

const { Search } = Input;

const UserList = ({
  calltype,
  ids,
  onCloseModal,
  data,
  onSubmit,
  loading,
}: {
  calltype?: "audio" | "video";
  ids: Array<string>;
  data: any;
  onSubmit?: (e: string[]) => void;
  loading?: boolean;
  onCloseModal?: any;
}) => {
  const [CheckList, setCheckList] = useState<Array<string>>(ids);
  const [LocSearch, setLocSearch] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [TOKEN, setTOKEN] = useState<string>("");
  const [CHANNEL, setCHANNEL] = useState<string>("");
  const [ID, setID] = useState<string>("");
  const [Loading, setLoading] = useState<boolean>(false);
  const [callType, setCallType] = useState<string | number>(0);

  const handleChangeOpen = (open: boolean) => {
    setOpen(open);
  };

  // Function inside UserList
  const closeAndDoSomething = () => {
    // Do something...
    onCloseModal(); // Close the modal
  };
  return (
    <div>
      <div
        className={profileStyles.profile__form__container}
        style={{ flexDirection: "column" }}
      >
        <div className={profileStyles.search__step__two}>
          <Search
            placeholder="Search..."
            onChange={(e: any) => setLocSearch(e.target.value)}
            onSearch={(e: any) => setLocSearch(e)}
            prefix={<img src="/images/search.png" alt="" />}
          />
        </div>
        <div
          className={profileStyles.contact__container}
          style={{ height: "50vh" }}
        >
          <Checkbox.Group
            style={{ width: "90%", margin: "0 auto" }}
            value={CheckList}
            onChange={(e: any) => {
              setCheckList(calltype ? e : e[e.length - 1]);
              console.log(CheckList, e[e.length - 1]);
            }}
          >
            {data
              ?.filter((c: any) => {
                const name = c?.contact?.full_name ?? c?.full_name ?? c?.name;
                return name.toLowerCase().match(LocSearch.toLowerCase());
              })
              .map((user: any) => (
                <div className={profileStyles.perm__container}>
                  <div
                    className={profileStyles.part__1}
                    style={{ width: "auto" }}
                  >
                    <Avatar
                      src={
                        user?.contact?.profile_image?.url ??
                        user?.profile_image?.url ??
                        user?.icon?.url
                      }
                      size={44}
                    />
                    <div className={profileStyles.name}>
                      {user?.contact?.full_name ??
                        user?.full_name ??
                        user?.name}
                    </div>
                  </div>

                  <div
                    className={profileStyles.part__2}
                    style={{ width: "auto" }}
                  >
                    <Checkbox value={`${user?.contact?.id ?? user?.id}`} />
                  </div>
                </div>
              ))}
          </Checkbox.Group>
        </div>
      </div>
      <div style={{ textAlign: "end" }}>
        <Button
          className="cancelbtn"
          onClick={() => {
            closeAndDoSomething();
          }}
        >
          Cancel
        </Button>{" "}
        <Button
          className="createbtn"
          type="primary"
          htmlType="submit"
          disabled={Loading}
          style={{ background: Loading ? "grey !important" : "" }}
          onClick={() => {
            if (CheckList) {
              setLoading(true);
              if (calltype === "video") {
                setCallType(1);
              } else {
                setCallType(0);
              }
              const formData = new FormData();
              formData.append("is_video", `${calltype === "video" ? 1 : 0}`);
              // Now, assuming you want to append each user ID to formData
              CheckList.forEach((userId: any) => {
                formData.append("user_ids[]", userId);
              });
              // formData.append(
              //   "user_ids[]",
              //   `${
              //     JSON.parse(window.localStorage.getItem("user") as string).id
              //   }`
              // );

              StartCall(formData)
                .then((res: any) => {
                  setTOKEN(res?.json.agora_access_token);
                  setCHANNEL(res?.json.agora_channel_name);
                  setID(res?.json.id);
                  setLoading(false);
                  // closeAndDoSomething();
                  handleChangeOpen(true);
                })
                .catch((err) => {
                  setLoading(false);
                });
            }
          }}
        >
          <>{Loading ? " • • • " : `Start ${calltype} call`}</>
        </Button>
      </div>

      {open ? (
        <>
          <VideoRoom
            TOKEN={TOKEN}
            CHANNEL={CHANNEL}
            IncomingCallInfo={ID}
            isModalOpen={open}
            handleChangeOpen={handleChangeOpen}
            callType={callType}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserList;

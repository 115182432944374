import React, { useEffect, useState } from "react";
import spacesStyles from "../../../../styles/pages/spaces.module.scss";
import { Input } from "antd";
import { SendMessages } from "../../../../services/chats";
import { useQueryClient } from "react-query";

const MediaPreview = ({
  setFilesData,
  FilesData,
  MessagesSetting,
  setMessagesSetting,
  setReply,
  contact,
  ContactRoom,
  roomId,
  reply,
  divForAutoScroll,
}: any) => {
  const [Images, setImages] = useState<any>([]);
  const [Text, setText] = useState<any>("");
  const [ActiveImage, setActiveImage] = useState<any>(0);
  const queryClient = useQueryClient();
  useEffect(() => {
    let images = [];
    if (!!FilesData?.files.length) {
      for (const item of FilesData?.files) {
        images.push(URL.createObjectURL(new Blob([item as any])));
      }
    }
    console.log(images);
    setImages(images);
  }, [FilesData?.files]);
  const sendFile = () => {
    for (var x = 0; x < FilesData?.files.length; x++) {
      let newMessages = MessagesSetting.data;
      if (FilesData.type === "image")
        newMessages.unshift({
          text: Text,
          is_mine: 1,
          is_seen: "pending",
          type: 4,
          image: [{ url: Images[x] }],
        });
      else
        newMessages.unshift({
          text: Text,
          is_mine: 1,
          is_seen: "pending",
          type: 5,
          video: [{ url: Images[x] }],
        });
      setFilesData({});
      setMessagesSetting({ ...MessagesSetting, data: newMessages });
      var formData = new FormData();
      var file;
      if (FilesData.type === "image") {
        formData.append(`content[image]`, FilesData?.files[x]);
        formData.append("type", "4");
      } else {
        formData.append(`content[video]`, FilesData?.files[x]);
        formData.append("type", "5");
      }
      formData.append("chat_id", contact ? ContactRoom : roomId);
      formData.append("text", Text);
      if (reply.id !== 0)
        formData.append("reply_message_id", reply.id.toString());

      setReply({
        id: 0,
        message: "",
        sender: "",
        is_corrected: false,
      });
      SendMessages(formData).then(() => {
        queryClient.invalidateQueries("Messages");
        queryClient.invalidateQueries("UsersChats");
        divForAutoScroll.current.scrollIntoView({ behavior: "smooth" });
      });
    }
  };
  return (
    <div className={spacesStyles.mediaPreview}>
      <button
        onClick={() => setFilesData({})}
        className={spacesStyles.closeBtn}
      >
        <img src="/images/cancel.svg" alt="" />
      </button>
      {FilesData.type === "image" ? (
        <img
          className={spacesStyles.mainImg}
          src={Images[ActiveImage]}
          alt=""
        />
      ) : (
        <video
          className={spacesStyles.mainImg}
          src={Images[ActiveImage]}
          controls
        ></video>
      )}

      <div className={spacesStyles.imagesCon}>
        {FilesData.type === "image" ? (
          <>
            {Images?.map((file: any, index: number) => (
              <img
                onClick={() => setActiveImage(index)}
                className={
                  ActiveImage === index
                    ? spacesStyles.smallImg + " " + spacesStyles.active
                    : spacesStyles.smallImg
                }
                src={file}
                alt=""
              />
            ))}
          </>
        ) : (
          <>
            {Images?.map((file: any, index: number) => (
              <video
                onClick={() => setActiveImage(index)}
                className={
                  ActiveImage === index
                    ? spacesStyles.smallImg + " " + spacesStyles.active
                    : spacesStyles.smallImg
                }
                src={file}
              ></video>
            ))}
          </>
        )}
      </div>
      <div className={spacesStyles.inputCon}>
        <Input
          onChange={(e) => setText(e.target.value)}
          bordered={false}
          placeholder="Caption (optional)"
        />
        <button onClick={() => sendFile()} className={spacesStyles.sendBtn}>
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4854 15.4083L14.7268 19.0936C15.9277 20.4589 16.5282 21.1416 17.1567 20.9754C17.7852 20.8092 18.0008 19.9108 18.4318 18.1138L20.8229 8.1459C21.4868 5.37832 21.8187 3.99454 21.0808 3.312C20.343 2.62947 19.064 3.13725 16.5061 4.15282L4.63876 8.86449C2.59293 9.67674 1.57001 10.0829 1.50507 10.7808C1.49842 10.8522 1.49831 10.9241 1.50474 10.9955C1.56754 11.6937 2.58921 12.1033 4.63255 12.9223C5.55838 13.2934 6.0213 13.479 6.3532 13.8344C6.39052 13.8743 6.4264 13.9157 6.46078 13.9584C6.76658 14.3384 6.89709 14.8371 7.15808 15.8344L7.64653 17.701C7.9005 18.6715 8.02749 19.1568 8.36008 19.223C8.69267 19.2891 8.98225 18.8867 9.5614 18.0819L11.4854 15.4083ZM11.4854 15.4083L11.1676 15.0771C10.8059 14.7001 10.6251 14.5117 10.6251 14.2775C10.6251 14.0433 10.8059 13.8548 11.1676 13.4778L14.7406 9.75409"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default MediaPreview;

import React, { createContext, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider, QueryKey } from "react-query";
import { ConfigProvider } from "antd";
import Pusher from "pusher-js";
import "./App.scss";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Login from "./pages/auth/Login";
import Spaces from "./pages/spaces";
import Nav from "./components/layout/Nav";
import Profile from "./pages/users/Profile";
import MessagesPage from "./pages/messages";
import UsersMangment from "./pages/users";
import Call from "./pages/call";
import ContactsPage from "./pages/contacts";
import IncommingCall from "./components/call/IncommingCall";
import { notification } from "antd";
import { RejectCall } from "./services/calls";
import { FetchGroups } from "./services/groups";
import { FetchMessages, FetchUsersMessages, MakeSeen } from "./services/chats";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";

import { database } from "./firebaseConfig";
import { ref, set, get, child } from "firebase/database";
import moment from "moment";

export const MessageContext = createContext<any>(null);
export const RoomContext = createContext<any>(null);
export const LastContext = createContext<any>(null);
export const NotificationContext = createContext<any>(null);
export const CallContext = createContext<any>([]);
export const CallContextEnd = createContext<any>(false);

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      },
    },
  });

  const localStoragePersistor = createWebStoragePersistor({
    storage: window.localStorage,
  });

  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
    maxAge: 1000 * 60 * 60 * 24,
    hydrateOptions: {},
    dehydrateOptions: {
      shouldDehydrateQuery: (query) => {
        const queryIsReadyForPersistance = query.state.status === "success";
        if (queryIsReadyForPersistance) {
          const { queryKey } = query;
          const excludeFromPersisting = queryKey.includes("Messages");
          return !excludeFromPersisting;
        }
        return queryIsReadyForPersistance;
      },
    },
  });
  const [UserLoc, setUserLoc] = useState<any>({});
  const [NewMessage, SetNewMessage] = useState<any>();
  const [Room, SetRoom] = useState<any>(0);
  const [Last, setLast] = useState<any>({});
  const [NotificationRoom, SetNotificationRoom] = useState<any>(0);
  const [incomingCall, setIncomingCall] = useState(false); // State to track incoming call
  const [IncomingCallInfo, setIncomingCallInfo] = useState<any>({});
  const [FinsishCallInfo, setFinsishCallInfo] = useState<any>({});
  const [dataCall, setDataCall] = useState<any>([]);
  const [dataCallEnd, setDataCallEnd] = useState<any>(false);
  const [allMessagesId, setAllMessages] = useState<any>([]);
  const [audio] = useState(new Audio("/call.mp3"));
  const navigate = useNavigate();
  useEffect(() => {
    if (UserLoc?.id){
      console.log("momentmoment",moment.now())
      set(ref(database, UserLoc?.id.toString()), {
        last_seen: moment.now(),
        presence: true,
      });
      window.addEventListener("beforeunload", function(e){
        set(ref(database, UserLoc?.id.toString()), {
          last_seen: moment.now(),
          presence: false,
        });
     });
    }
  }, [UserLoc]);
  useEffect(() => {
    if (Object.keys(UserLoc).length > 0) {
      FetchGroups(1, 0, "").then((res) => {
        res?.json.map((gro: any) => {
          if (gro?.staff_chat?.id) {
            FetchMessages(gro?.staff_chat?.id, 1, 0, "").then((res1) => {
              res1?.json.map((msg: any) => {
                let newIds = allMessagesId;
                newIds.push(msg?.id);
                setAllMessages([...newIds]);
              });
            });
          }
          FetchMessages(gro.id, 1, 0, "").then((res1) => {
            res1?.json.map((msg: any) => {
              let newIds = allMessagesId;
              newIds.push(msg?.id);
              setAllMessages([...newIds]);
            });
          });
        });
      });

      FetchUsersMessages(1, "").then((res) => {
        res?.json.map((gro: any) => {
          FetchMessages(gro.id, 1, 0, "").then((res1) => {
            res1?.json.map((msg: any) => {
              let newIds = allMessagesId;
              newIds.push(msg?.id);
              setAllMessages([...newIds]);
            });
          });
        });
      });
    }
  }, []);
  useEffect(() => {
    console.log("allMessagesId", allMessagesId);
    console.log("allMessagesId", allMessagesId.length);
  }, [allMessagesId]);
  useEffect(() => {
    if (incomingCall) {
      audio.play();
      document.title = "CALL 🔴";
    } else {
      document.title = "H1Communicator";
      audio.pause();
      audio.currentTime = 0; // Reset audio to the beginning
    }
  }, [incomingCall]);

  useEffect(() => {
    console.log("Room Room id", Room);
  }, [Room]);

  const handleChangeIncomingCall = (incomingCallStatus: boolean) => {
    setIncomingCall(incomingCallStatus);
  };

  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
    for (let i = 0; i < 30; i++) {
      // RejectCall(7920 + i);
    }
  }, []);

  Pusher.log = (msg) => {
    console.log("data log", msg);
  };

  useEffect(() => {
    const pako = require("pako");
    const atob = require("atob");
    if (UserLoc?.id) {
      const pusher = new Pusher("3c66a6b21dab57125df0", {
        cluster: "ap1",
        channelAuthorization: {
          endpoint: "https://cascade.wealthcoders.ae/api/broadcasting/auth",
          transport: "ajax",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      });

      const channel = pusher.subscribe(`private-channel-user-${UserLoc.id}`);
      channel.bind("incoming_call", function (data: any) {
        setIncomingCall(true);
        setIncomingCallInfo(data);
        setDataCall(data);
      });
      channel.bind("finish_call", function (data: any) {
        setIncomingCall(false); // Set incoming call state to true when there's an incoming call event
        setFinsishCallInfo(data);
        setDataCall(data);
      });

      channel.bind("sync", function (data: any) {
        var b64Data = data.payload;
        var strData = atob(b64Data);
        var charData = strData.split("").map(function (x: any) {
          return x.charCodeAt(0);
        });
        var binData = new Uint8Array(charData);
        var data = pako.inflate(binData);
        var strData: any = String.fromCharCode.apply(
          null,
          new Uint16Array(data) as any
        );
        var newObject = JSON.parse(strData);
        console.log("checkNotification", newObject?.instanceof === 302);
        console.log("newObject", newObject);
        if (newObject?.instanceof === 302) {
          console.log(
            "checkNotification",
            newObject?.data?.chat_id.toString(),
            localStorage.getItem("roomId")
          );
          if (
            newObject?.data?.chat_id.toString() ===
            localStorage.getItem("roomId")
          ) {
            var wasTriggered = channel.trigger("client-sync", {
              hash: [newObject?.hash.toString()],
              seen: true,
            });
          } else {
            var wasTriggered = channel.trigger("client-sync", {
              hash: [newObject?.hash.toString()],
            });
          }
          if (
            newObject?.action === 111 &&
            newObject?.data?.chat_id ==
              parseInt(localStorage.getItem("roomId") as string)
          ) {
            SetNewMessage(newObject?.data);
          } else if (newObject?.action === 111) {
            SetNewMessage({ ...newObject?.data, outFetch: true });
          }
          // queryClient.invalidateQueries("groupsData");
          // queryClient.invalidateQueries("UsersChats");

          if (
            newObject?.data?.text &&
            newObject?.data?.is_mine === 0 &&
            newObject?.data?.chat_id !==
              parseInt(localStorage.getItem("roomId") as string)
          ) {
            console.log(
              "checkNotification",
              allMessagesId?.includes(newObject?.data?.id),
              allMessagesId,
              newObject?.data?.id
            );
            if (
              allMessagesId.length !== 0 ||
              !allMessagesId?.includes(newObject?.data?.id)
            ) {
              // notification.config({ maxCount: 2 });
              // notification.destroy();
              let newIds = allMessagesId;
              newIds.push(newObject?.data?.id);
              setAllMessages([...newIds]);
              notification.open({
                onClick: () => {
                  console.log("RoomRoomchangedappp", newObject?.data);
                  if (newObject?.data?.chat_type === 1) navigate("/messages");
                  else navigate("/spaces");
                  SetNotificationRoom(newObject?.data);
                },
                className: "messageNotification",
                message:
                  newObject?.data?.chat_type === 1
                    ? newObject?.data?.user?.full_name
                    : newObject?.data?.chat_name +
                      " - " +
                      newObject?.data?.user?.full_name,
                description: newObject?.data?.text,

                icon: (
                  <img
                    style={{
                      width: 50,
                      height: 50,
                      objectFit: "cover",
                      borderRadius: "50%",
                      marginInlineEnd: 10,
                    }}
                    src={newObject?.data?.user?.profile_image.url}
                  />
                ),
              });
            }
          }
        }
        if (newObject?.instanceof === 304) {
          SetNewMessage({ refetch: true });
        }
        if (newObject?.instanceof === 305) {
          SetNewMessage({ refetch: true });
        }
      });
    }
  }, [UserLoc]);

  useEffect(() => {}, [useLocation()]);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#9b1724",
            // Alias Token
            colorBgContainer: "#f1f2f4",
          },
        }}
      >
        <CallContext.Provider value={dataCall}>
          <MessageContext.Provider value={[NewMessage, SetNewMessage]}>
            <NotificationContext.Provider
              value={[NotificationRoom, SetNotificationRoom]}
            >
              <RoomContext.Provider value={[Room, SetRoom]}>
                <LastContext.Provider value={[Last, setLast]}>
                  <div
                    className="App"
                    style={{
                      height: "100vh",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {useLocation().pathname !== "/login" && <Nav />}{" "}
                    {/* Render Nav only if token exists */}
                    <div
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        minHeight: 0,
                      }}
                    >
                      <Routes>
                        <Route path="/users" Component={UsersMangment} />
                        <Route path="/login" Component={Login} />
                        <Route path="/spaces" Component={Spaces} />
                        <Route path="/contacts" Component={ContactsPage} />
                        <Route path="/messages" Component={MessagesPage} />
                        <Route path="/users/profile" Component={Profile} />
                        <Route path="/calls" Component={Call} />
                        <Route path="*" element={<Navigate to="/spaces" />} />
                      </Routes>
                      {incomingCall && (
                        <IncommingCall
                          IncomingCallInfo={IncomingCallInfo}
                          FinsishCallInfo={FinsishCallInfo}
                          handleChangeIncomingCall={handleChangeIncomingCall}
                          audio={audio}
                        />
                      )}{" "}
                      {/* Render IncommingCall component if there's an incoming call */}
                    </div>
                  </div>
                </LastContext.Provider>
              </RoomContext.Provider>
            </NotificationContext.Provider>
          </MessageContext.Provider>
        </CallContext.Provider>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;

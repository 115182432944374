import {
  CloseOutlined,
  PhoneFilled,
  PhoneOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Avatar, Button } from "antd";
import React, { createContext, useEffect, useState } from "react";
import { AnswerCall, RejectCall } from "../../services/calls";
import VideoRoom from "./VideoRoom";

const IncommingCall = ({
  FinsishCallInfo,
  IncomingCallInfo,
  handleChangeIncomingCall,
  audio,
}: any) => {
  console.log(FinsishCallInfo, IncomingCallInfo, "here");
  const [Joined, setJoined] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [UsersInfo, setUsersInfo] = useState<any>([]);

  const handleChangeOpen = (open: boolean) => {
    setOpen(open);
  };

  useEffect(() => {
    // Extract usernames from the caller object
    const callerUsernames = [IncomingCallInfo.caller];

    // Extract usernames from the receivers array
    const receiverUsernames = IncomingCallInfo.receivers.map(
      (receiver: any) => receiver
    );

    // Combine both arrays into a single array of all usernames
    const allUsernames = [...callerUsernames, ...receiverUsernames];
    setUsersInfo(allUsernames);

    console.log(allUsernames, "here names");
  }, [IncomingCallInfo]);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .ant-popover img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
    `,
        }}
      />

      {!Joined ? (
        <div className="call__container">
          <div
            className="flex flex-col items-center justify-center wrapper-caller-info"
            style={{ minHeight: "100%" }}
          >
            <section className="caller-info flex flex-col items-center flex-auto justify-center">
              <div className="circle avatar-caller mb4">
                <Avatar.Group maxCount={2} size={150}>
                  {UsersInfo ? (
                    UsersInfo.map((userItem: any, index: number) => (
                      <div>
                        {userItem?.profile_image?.url ? (
                          <Avatar
                            size={150}
                            src={userItem?.profile_image?.url}
                          ></Avatar>
                        ) : (
                          <Avatar size={150}>
                            {userItem?.username?.slice(0, 2)}
                          </Avatar>
                        )}
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </Avatar.Group>
              </div>
              <div className="flex justify-center items-baseline">
                {UsersInfo.slice(0, 2).map((userItem: any, index: number) => (
                  <span
                    style={{ fontSize: "25px", margin: "10px" }}
                  >{`${userItem.full_name},`}</span>
                ))}

                {UsersInfo.length > 2 && <span>•••</span>}
              </div>
            </section>

            {IncomingCallInfo.is_video ? (
              <section className="call-buttons flex justify-between">
                <div className="btn-floating-label">
                  <Button
                    onClick={() => {
                      RejectCall(`${IncomingCallInfo.id}`).then((res: any) => {
                        console.log("here is the res", res);
                      });
                      handleChangeIncomingCall(false);
                    }}
                    className="btn-floating btn-jumbo waves-effect waves-light danger"
                  >
                    <CloseOutlined />
                  </Button>
                </div>
                <div className="btn-floating-label">
                  <Button
                    onClick={() => {
                      const formData = new FormData();
                      formData.append("id", `${IncomingCallInfo.id}`);
                      AnswerCall(formData).then((res: any) => {
                        console.log("here is the res", res);
                      });
                      audio.pause();
                      audio.currentTime = 0; // Reset audio to the beginning

                      setJoined(true);
                      setOpen(true);
                    }}
                    className="btn-floating btn-jumbo waves-effect waves-light success"
                    id="accept"
                  >
                    <VideoCameraOutlined />
                  </Button>
                </div>
              </section>
            ) : (
              <section className="call-buttons flex justify-between">
                <div className="btn-floating-label">
                  <Button
                    onClick={() => {
                      RejectCall(`${IncomingCallInfo.id}`).then((res: any) => {
                        console.log("here is the res", res);
                      });
                      handleChangeIncomingCall(false);
                    }}
                    className="btn-floating btn-jumbo waves-effect waves-light danger"
                    id="reject"
                  >
                    <PhoneFilled />
                  </Button>
                </div>
                <div className="btn-floating-label">
                  <Button
                    onClick={() => {
                      const formData = new FormData();
                      formData.append("id", `${IncomingCallInfo.id}`);
                      AnswerCall(formData).then((res: any) => {
                        console.log("here is the res", res);
                      });
                      audio.pause();
                      audio.currentTime = 0; // Reset audio to the beginning

                      setJoined(true);
                      setOpen(true);
                    }}
                    className="btn-floating btn-jumbo waves-effect waves-light success"
                    id="accept"
                  >
                    <PhoneFilled />
                  </Button>
                </div>
              </section>
            )}
          </div>
        </div>
      ) : (
        <>
          {open ? (
            <>
              <VideoRoom
                IncomingCallInfo={IncomingCallInfo.id}
                TOKEN={IncomingCallInfo.agora_access_token}
                CHANNEL={IncomingCallInfo.agora_channel_name}
                isModalOpen={open}
                handleChangeOpen={handleChangeOpen}
                callType={IncomingCallInfo.is_video}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default IncommingCall;

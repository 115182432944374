
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAd043idPkQSb2S0KwjCftrzeWHw0USbvk",
  authDomain: "whitebird-d7d4b.firebaseapp.com",
  databaseURL: "https://whitebird-d7d4b-default-rtdb.firebaseio.com",
  projectId: "whitebird-d7d4b",
  storageBucket: "whitebird-d7d4b.appspot.com",
  messagingSenderId: "978899863683",
  appId: "1:978899863683:web:de9eef36550eebf5b133b6",
  measurementId: "G-04QC4BTH4V"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { app, database };
import { Button, Modal, notification, Tabs } from "antd";
import { useContext, useEffect, useState } from "react";
import memberStyles from "./styles.module.scss";
import { useQuery, useQueryClient } from "react-query";
import { FetchMember } from "../../../../services/chats";
import ManageUsers from "../../addGroup/manageUsers";
import { CloseOutlined } from "@ant-design/icons";
import { FetchContacts } from "../../../../services/contacts";
import { RoomContext } from "../../../../App";

const Members = ({ roomId, refetchGroups }: any) => {
  const [Perms, setPerms] = useState<any>([]);
  const [UserLoc, setUserLoc] = useState<any>({});

  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, []);
  const [Room, SetRoom] = useContext<any>(RoomContext);
  const { data, status, isLoading, refetch } = useQuery(
    ["member", Room],
    async () => FetchMember(Room),
    {
      cacheTime: 0,
      staleTime: 10000,
      enabled: Room !== undefined && Room !== 0,
    }
  );

  const Contacts = useQuery(["Contacts"], async () => FetchContacts(0), {
    cacheTime: 0,
    staleTime: 10000,
  });

  useEffect(() => {
    if (data && data?.json && data.json.members) {
      setPerms(
        data.json.members?.map((user: any) => {
          let permsUser = [];
          if (user?.props?.find((x: any) => x.perm === 1)?.allow === 1)
            permsUser.push(1);
          if (user?.props?.find((x: any) => x.perm === 2)?.allow === 1)
            permsUser.push(2);
          if (user?.props?.find((x: any) => x.perm === 3)?.allow === 1)
            permsUser.push(3);
          if (user?.props?.find((x: any) => x.perm === 4)?.allow === 1)
            permsUser.push(4);
          if (user?.props?.find((x: any) => x.perm === 5)?.allow === 1)
            permsUser.push(5);
          return {
            id: user?.id,
            name: user?.full_name,
            perm: permsUser,
          };
        })
      );
    }
  }, [data]);

  const EditManageOpenModal = () => {
    const createModal = Modal.info({
      className: "addspaceModal",
      title: "Manage users",
      width: "40%",
      icon: <></>,
      content: (
        <ManageUsers
          members={data?.json?.members}
          Perms={Perms}
          setPerms={(newParms: any) => {
            setPerms([...newParms]);
          }}
          Contacts={Contacts}
          RoomId={roomId}
        />
      ),
      footer: [],
      onOk() {
        refetch();
        refetchGroups();
      },
      onCancel() {
        refetch();
      },
      afterClose() {
        refetch();
        refetchGroups();
      },
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
      autoFocusButton: null,
    });
  };

  const View = ({ type }: { type: "internal" | "external" }) => {
    const condition = (role: any) =>
      type === "internal"
        ? role !== "client"
        : type === "external" && role === "client";

    return (
      <div className={memberStyles.membersView}>
        <div className={memberStyles.head}>
          <div className={memberStyles.count}>
            {(data?.json &&
              data?.json?.members
                ?.filter((user: any) => condition(user?.role))
                ?.filter((user: any) =>
                  data?.json?.members.find((x: any) => x.id === UserLoc?.id)
                    ?.is_admin === 1
                    ? true
                    : user?.props?.find((x: any) => x.perm === 3)?.allow !==
                        1 ||
                      user?.is_admin === 1 ||
                      user?.id === UserLoc?.id
                ).length) ??
              0}{" "}
            Members
          </div>
          {(UserLoc?.role === "owner" ||
            UserLoc?.role === "su" ||
            UserLoc?.role === "admin") &&
          data?.json?.is_admin ? (
            <div
              className={memberStyles.manageBtn}
              onClick={() => EditManageOpenModal()}
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9332 6.29255C10.9332 7.91412 9.61871 9.22866 7.99714 9.22866C6.37557 9.22866 5.06104 7.91412 5.06104 6.29255C5.06104 4.67098 6.37557 3.35645 7.99714 3.35645C9.61871 3.35645 10.9332 4.67098 10.9332 6.29255Z"
                  stroke="url(#paint0_linear_3010_597)"
                  stroke-width="1.25833"
                />
                <path
                  d="M12.1917 9.22866C13.8132 9.22866 15.1278 7.91412 15.1278 6.29255C15.1278 4.67098 13.8132 3.35645 12.1917 3.35645"
                  stroke="url(#paint1_linear_3010_597)"
                  stroke-width="1.25833"
                  stroke-linecap="round"
                />
                <path
                  d="M11.8921 16.7775H4.10237C3.24194 16.7775 2.54443 16.1336 2.54443 15.3394C2.54443 13.3538 4.28821 11.7441 6.43927 11.7441H9.5551C10.4319 11.7441 11.2411 12.0116 11.8921 12.4629"
                  stroke="url(#paint2_linear_3010_597)"
                  stroke-width="1.25833"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.8055 11.7441V16.7775M19.3221 14.2608H14.2888"
                  stroke="url(#paint3_linear_3010_597)"
                  stroke-width="1.25833"
                  stroke-linecap="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_3010_597"
                    x1="11.1975"
                    y1="2.94539"
                    x2="4.67934"
                    y2="9.93332"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#BF4034" />
                    <stop offset="1" stop-color="#9B1724" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_3010_597"
                    x1="15.2599"
                    y1="2.94539"
                    x2="9.81851"
                    y2="5.86216"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#BF4034" />
                    <stop offset="1" stop-color="#9B1724" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_3010_597"
                    x1="12.3127"
                    y1="11.3918"
                    x2="7.82018"
                    y2="20.3364"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#BF4034" />
                    <stop offset="1" stop-color="#9B1724" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_3010_597"
                    x1="19.5486"
                    y1="11.3918"
                    x2="13.9617"
                    y2="17.3815"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#BF4034" />
                    <stop offset="1" stop-color="#9B1724" />
                  </linearGradient>
                </defs>
              </svg>
              MANAGE USERS
            </div>
          ) : (
            ""
          )}
        </div>
        {data?.json &&
          data?.json?.members
            ?.filter((user: any) => condition(user?.role))
            ?.filter((user: any) =>
              data?.json?.members.find((x: any) => x.id === UserLoc?.id)
                ?.is_admin === 1
                ? true
                : user?.props?.find((x: any) => x.perm === 3)?.allow !== 1 ||
                  user?.is_admin === 1 ||
                  user?.id === UserLoc?.id
            )
            .map((user: any, index: number) => (
              <div className={memberStyles.memberItem}>
                <div className={memberStyles.icon}>
                  <img height={20} src={user?.profile_image?.url} alt="" />
                </div>
                <div className={memberStyles.info}>
                  <div className={memberStyles.name}>{user?.full_name}</div>
                  <div className={memberStyles.role}>
                    {user?.role === "staff"
                      ? "internal"
                      : user?.role === "client"
                      ? "external"
                      : user?.role === "su"
                      ? "Super admin"
                      : user?.role === "op"
                      ? "Owner"
                      : user?.role}
                  </div>
                </div>
              </div>
            ))}
      </div>
    );
  };

  return (
    <div className={memberStyles.members}>
      {UserLoc?.role === "client" ? (
        <View type="external" />
      ) : (
        <Tabs
          className={memberStyles.tabs}
          defaultActiveKey="1"
          items={[
            {
              key: "1",
              label: "Internal",
              children: <View type="internal" />,
            },
            {
              key: "2",
              label: "External",
              children: <View type="external" />,
            },
          ]}
        />
      )}
    </div>
  );
};

export default Members;

import React, { useContext } from "react";
import { useQuery } from "react-query";
import { FetchFiles } from "../../../../services/chats";
import { Col, Row } from "antd";
import filesStyles from "./styles.module.scss";
import moment from "moment";
import { RoomContext } from "../../../../App";

const Files = ({ roomId }: any) => {
  const [Room, SetRoom] = useContext<any>(RoomContext);
  const { data, status, isLoading } = useQuery(["Files", Room], async () =>
    FetchFiles(Room)
  ,
    {
      cacheTime: 0,
      staleTime: 10000,
      enabled: Room !== 0,
    });
  return (
    <div className={filesStyles.files}>
      <div className={filesStyles.count}>
        {(data?.json && data?.json?.data?.length) ?? 0} Files
      </div>
      <Row>
        {data?.json?.data?.map((file: any) => (
          <Col span={24}>
            <div className={filesStyles.fileCard}>
              <img
                className={filesStyles.fileIcon}
                src={"/images/file.svg"}
                alt=""
              />
              <div className={filesStyles.fileInfo}>
                <div className={filesStyles.fileName}>{file.file_name}</div>
                <div className={filesStyles.info}>
                  {Number(file.size / 1024 / 1024).toFixed(2) + " Mb"} •{" "}
                  {moment(file.created_at).format("DD MMM, yyyy")}
                </div>
              </div>

              <a href={file.url} download target="_blank" rel="noreferrer">
                <svg
                  className={filesStyles.downFileIcon}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 14.5V4.5M12 14.5C11.2998 14.5 9.99153 12.5057 9.5 12M12 14.5C12.7002 14.5 14.0085 12.5057 14.5 12"
                    stroke="#0D0F11"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20 16.5C20 18.982 19.482 19.5 17 19.5H7C4.518 19.5 4 18.982 4 16.5"
                    stroke="#0D0F11"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Files;

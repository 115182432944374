import React, { useEffect, useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Button, Form, type FormProps, Input, Typography } from "antd";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { Avatar, Space } from "antd";
import navStyles from "../../styles/layout/nav.module.scss";

import { DownloadOutlined } from "@ant-design/icons";
import { Divider, Flex, Radio } from "antd";
import type { ConfigProviderProps } from "antd";
import { logout } from "../../actions/auth";

type SizeType = ConfigProviderProps["componentSize"];

// Need to be edited
type FieldType = {
  search?: string;
};
function Nav() {
  const history = useNavigate();

  const [size, setSize] = useState<SizeType>("large"); // default is 'middle'
  const [UserLoc, setUserLoc] = useState<any>({});
  const [GroupNoti, setGroupNoti] = useState<any>(0);
  const [PrivateNoti, setPrivateNoti] = useState<any>(0);

  const [form] = Form.useForm();
  const [clientReady, setClientReady] = useState<boolean>(false);
  const onFinish = (values: any) => {
    console.log("Finish:", values);
  };

  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, [localStorage.getItem("user")]);
  useEffect(() => {
    const t = JSON.parse(
      window.localStorage.getItem("privateNotification") as string
    );
    setPrivateNoti(t);
  }, [localStorage.getItem("privateNotification")]);
  useEffect(() => {
    const t = JSON.parse(
      window.localStorage.getItem("groupsNotification") as string
    );
    setGroupNoti(t);
  }, [localStorage.getItem("groupsNotification")]);

  const [current, setCurrent] = useState("mail");

  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  const itemsNot: MenuProps["items"] = [
    {
      key: "SubMenu",
      icon: (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.02992 14.394C2.81727 15.7471 3.768 16.6862 4.93205 17.1542C9.39481 18.9486 15.6052 18.9486 20.0679 17.1542C21.232 16.6862 22.1827 15.7471 21.9701 14.394C21.8394 13.5625 21.1932 12.8701 20.7144 12.194C20.0873 11.2975 20.025 10.3197 20.0249 9.27941C20.0249 5.2591 16.6559 2 12.5 2C8.34413 2 4.97513 5.2591 4.97513 9.27941C4.97503 10.3197 4.91272 11.2975 4.28561 12.194C3.80684 12.8701 3.16061 13.5625 3.02992 14.394Z"
            stroke="#0D0F11"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.5 21C10.2961 21.6219 11.3475 22 12.5 22C13.6525 22 14.7039 21.6219 15.5 21"
            stroke="#0D0F11"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      children: [
        {
          type: "group",
          label: "Item 1",
          children: [
            {
              label: "Option 1",
              key: "setting:1",
            },
            {
              label: "Option 2",
              key: "setting:2",
            },
          ],
        },
        {
          type: "group",
          label: "Item 2",
          children: [
            {
              label: "Option 3",
              key: "setting:3",
            },
            {
              label: "Option 4",
              key: "setting:4",
            },
          ],
        },
      ],
    },
  ];

  const itemsProfile: MenuProps["items"] = [
    {
      key: "SubMenu",
      icon: (
        <Space size={16} wrap>
          <Avatar src={UserLoc?.profile_image?.url} size={35}></Avatar>
          <span>{UserLoc?.full_name}</span>
          <svg
            width="15"
            height="8"
            viewBox="0 0 15 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 1.00005C13.5 1.00005 9.0811 7 7.5 7C5.9188 7 1.5 1 1.5 1"
              stroke="#0D0F11"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Space>
      ),
      children: ((): MenuProps["items"] => {
        const items: MenuProps["items"] = [];
        items.push({
          label: (
            <NavLink to="/users/profile" className={navStyles.nav__item__menu}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 22H6.59087C5.04549 22 3.81631 21.248 2.71266 20.1966C0.453366 18.0441 4.1628 16.324 5.57757 15.4816C8.12805 13.9629 11.2057 13.6118 14 14.4281"
                  stroke="#3A3A3C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z"
                  stroke="#3A3A3C"
                  stroke-width="1.5"
                />
                <path
                  d="M18.4332 13.8485C18.7685 13.4851 18.9362 13.3035 19.1143 13.1975C19.5442 12.9418 20.0736 12.9339 20.5107 13.1765C20.6918 13.2771 20.8646 13.4537 21.2103 13.8067C21.5559 14.1598 21.7287 14.3364 21.8272 14.5214C22.0647 14.9679 22.0569 15.5087 21.8066 15.9478C21.7029 16.1298 21.5251 16.3011 21.1694 16.6437L16.9378 20.7194C16.2638 21.3686 15.9268 21.6932 15.5056 21.8577C15.0845 22.0222 14.6214 22.0101 13.6954 21.9859L13.5694 21.9826C13.2875 21.9752 13.1466 21.9715 13.0646 21.8785C12.9827 21.7855 12.9939 21.6419 13.0162 21.3548L13.0284 21.1988C13.0914 20.3906 13.1228 19.9865 13.2807 19.6232C13.4385 19.2599 13.7107 18.965 14.2552 18.375L18.4332 13.8485Z"
                  stroke="#3A3A3C"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
              </svg>

              <span>Edit account</span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18"
                  stroke="#8E8E93"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </NavLink>
          ),
          key: "setting:1",
        });
        if (
          UserLoc?.role === "owner" ||
          UserLoc?.role === "su" ||
          UserLoc?.role === "admin"
        ) {
          items.push({
            label: (
              <NavLink
                style={{
                  pointerEvents:
                    UserLoc?.role === "owner" ||
                    UserLoc?.role === "su" ||
                    UserLoc?.role === "admin"
                      ? "initial"
                      : "none",
                }}
                to="/users"
                className={navStyles.nav__item__menu}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.774 18C21.5233 18 22.1193 17.5285 22.6545 16.8691C23.7499 15.5194 21.9513 14.4408 21.2654 13.9126C20.568 13.3756 19.7894 13.0714 19 13M18 11C19.3807 11 20.5 9.88071 20.5 8.5C20.5 7.11929 19.3807 6 18 6"
                    stroke="#3A3A3C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M3.22596 18C2.47666 18 1.88067 17.5285 1.34555 16.8691C0.250087 15.5194 2.04867 14.4408 2.73465 13.9126C3.43197 13.3756 4.21058 13.0714 5 13M5.5 11C4.11929 11 3 9.88071 3 8.5C3 7.11929 4.11929 6 5.5 6"
                    stroke="#3A3A3C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M8.0838 15.1112C7.06203 15.743 4.38299 17.0331 6.0147 18.6474C6.81178 19.436 7.69952 20 8.81563 20H15.1844C16.3005 20 17.1882 19.436 17.9853 18.6474C19.617 17.0331 16.938 15.743 15.9162 15.1112C13.5201 13.6296 10.4799 13.6296 8.0838 15.1112Z"
                    stroke="#3A3A3C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.5 7.5C15.5 9.433 13.933 11 12 11C10.067 11 8.5 9.433 8.5 7.5C8.5 5.567 10.067 4 12 4C13.933 4 15.5 5.567 15.5 7.5Z"
                    stroke="#3A3A3C"
                    stroke-width="1.5"
                  />
                </svg>

                <span>Manage users</span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18"
                    stroke="#8E8E93"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </NavLink>
            ),
            key: "setting:2",
          });
        }
        // items.push({
        //   label: (
        //     <NavLink to="/twoFa" className={navStyles.nav__item__menu}>
        //       <svg
        //         width="24"
        //         height="24"
        //         viewBox="0 0 24 24"
        //         fill="none"
        //         xmlns="http://www.w3.org/2000/svg"
        //       >
        //         <path
        //           d="M4 17C4 19.2091 5.75326 21 7.91602 21C9.68999 21 11.1885 19.7951 11.6699 18.1429H15.8599C16.2819 18.1429 16.3075 18.169 16.3075 18.6V19.8571C16.3075 20.3959 16.3075 20.6653 16.4713 20.8326C16.6352 21 16.8989 21 17.4263 21H18.1874C18.6265 21 18.846 21 19.0007 20.8705C19.1554 20.741 19.1984 20.5211 19.2845 20.0813L19.5921 18.5103C19.661 18.1585 19.6797 18.1429 20.031 18.1429H20.7829C21.3104 18.1429 21.5741 18.1429 21.7379 17.9755C22.1045 17.6011 22.0698 15.7921 21.7379 15.4531C21.5741 15.2857 21.3104 15.2857 20.7829 15.2857H11.4552C10.8267 13.9343 9.47817 13 7.91602 13C5.75326 13 4 14.7909 4 17Z"
        //           stroke="#3A3A3C"
        //           stroke-width="1.5"
        //           stroke-linecap="round"
        //           stroke-linejoin="round"
        //         />
        //         <path
        //           d="M8.00896 17H8"
        //           stroke="#3A3A3C"
        //           stroke-width="2"
        //           stroke-linecap="round"
        //           stroke-linejoin="round"
        //         />
        //         <path
        //           d="M19 12.5V9C19 6.17157 19 4.75736 18.1213 3.87868C17.2426 3 15.8284 3 13 3H8C5.17157 3 3.75736 3 2.87868 3.87868C2 4.75736 2 6.17157 2 9V14"
        //           stroke="#3A3A3C"
        //           stroke-width="1.5"
        //           stroke-linecap="round"
        //           stroke-linejoin="round"
        //         />
        //       </svg>

        //       <span>2FA</span>
        //       <svg
        //         width="24"
        //         height="24"
        //         viewBox="0 0 24 24"
        //         fill="none"
        //         xmlns="http://www.w3.org/2000/svg"
        //       >
        //         <path
        //           d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18"
        //           stroke="#8E8E93"
        //           stroke-width="1.5"
        //           stroke-linecap="round"
        //           stroke-linejoin="round"
        //         />
        //       </svg>
        //     </NavLink>
        //   ),
        //   key: "setting:3",
        // });
        items.push({
          label: (
            <button
              className={navStyles.nav__item__menu}
              onClick={() => {
                logout()
                  .then(() => history("/login"))
                  .catch(() => history("/login"));
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 3.09502C13.543 3.03241 13.0755 3 12.6 3C7.29807 3 3 7.02944 3 12C3 16.9706 7.29807 21 12.6 21C13.0755 21 13.543 20.9676 14 20.905"
                  stroke="#FF3B30"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M21 12H11M21 12C21 11.2998 19.0057 9.99153 18.5 9.5M21 12C21 12.7002 19.0057 14.0085 18.5 14.5"
                  stroke="#FF3B30"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>Log out</span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18"
                  stroke="#8E8E93"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          ),
          key: "setting:4",
        });
        return items;
      })(),
    },
  ];

  return (
    <div className="nav__container">
      <nav id="navCon" className={navStyles.nav}>
        <div className={navStyles.part__1}>
          <img src="/logo.png" alt="" />
          {/* <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item<FieldType> name="search">
              <Input
                prefix={
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.7422 17.5L22.2422 22"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.2422 11C20.2422 6.02944 16.2128 2 11.2422 2C6.27163 2 2.24219 6.02944 2.24219 11C2.24219 15.9706 6.27163 20 11.2422 20C16.2128 20 20.2422 15.9706 20.2422 11Z"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                placeholder="Search"
              />
            </Form.Item>
          </Form> */}
        </div>
        <div className={navStyles.part__2}>
          <ul>
            <li>
              {GroupNoti && GroupNoti !== null && GroupNoti !== 0 ? (
                <span className={navStyles.notificationCount}>{GroupNoti}</span>
              ) : (
                <></>
              )}
              <NavLink
                to="/spaces"
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "nav__active" : ""
                }
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5911 17.4868C16.1467 17.2526 18.9789 14.4069 19.2119 10.8344C19.2575 10.1353 19.2575 9.41131 19.2119 8.71219C18.9789 5.13968 16.1467 2.29401 12.5911 2.05985C11.3781 1.97997 10.1116 1.98013 8.90108 2.05985C5.34552 2.29401 2.51334 5.13968 2.28029 8.71219C2.23469 9.41131 2.23469 10.1353 2.28029 10.8344C2.36517 12.1356 2.94601 13.3403 3.62981 14.3576C4.02685 15.0697 3.76482 15.9586 3.35127 16.735C3.05309 17.2948 2.904 17.5747 3.02371 17.7769C3.14341 17.9791 3.41081 17.9856 3.9456 17.9985C5.00321 18.024 5.71637 17.7269 6.28247 17.3134C6.60353 17.0788 6.76407 16.9615 6.87471 16.9481C6.98535 16.9346 7.20309 17.0234 7.6385 17.2011C8.02983 17.3608 8.48421 17.4593 8.90108 17.4868C10.1116 17.5665 11.3781 17.5666 12.5911 17.4868Z"
                    stroke="#8E8E93"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.74609 12H13.7461M7.74609 8H10.7461"
                    stroke="#8E8E93"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.74609 20C9.79647 20.8697 11.0606 21.4238 12.5096 21.5188C13.6513 21.5937 14.8432 21.5936 15.9826 21.5188C16.3749 21.4931 16.8026 21.4007 17.1709 21.251C17.5806 21.0845 17.7856 21.0012 17.8898 21.0138C17.9939 21.0264 18.145 21.1364 18.4472 21.3563C18.98 21.744 19.6512 22.0225 20.6466 21.9986C21.1499 21.9865 21.4016 21.9804 21.5142 21.7909C21.6269 21.6013 21.4866 21.3389 21.2059 20.8141C20.8167 20.0862 20.5701 19.2529 20.9438 18.5852C21.5874 17.6315 22.134 16.5021 22.2139 15.2823C22.2568 14.6269 22.2568 13.9481 22.2139 13.2927C22.1607 12.4799 21.9634 11.7073 21.6473 11"
                    stroke="#8E8E93"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span>Spaces</span>
              </NavLink>
            </li>
            <li>
              {" "}
              {PrivateNoti && PrivateNoti !== null && PrivateNoti !== 0 ? (
                <span className={navStyles.notificationCount}>
                  {PrivateNoti}
                </span>
              ) : (
                <></>
              )}
              <NavLink
                to="/messages"
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "nav__active" : ""
                }
              >
                <svg
                  width="23"
                  height="22"
                  viewBox="0 0 23 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.4961 12.4909C21.2214 16.7332 17.8827 20.1125 13.6912 20.3905C12.2613 20.4854 10.7683 20.4852 9.34127 20.3905C8.84985 20.3579 8.31421 20.2409 7.85289 20.0513C7.33961 19.8403 7.08293 19.7348 6.9525 19.7508C6.82207 19.7668 6.63283 19.9061 6.25434 20.1846C5.587 20.6757 4.7463 21.0285 3.49955 20.9982C2.86911 20.9829 2.55389 20.9752 2.41278 20.7351C2.27166 20.495 2.44741 20.1626 2.79892 19.4978C3.28644 18.5758 3.59532 17.5203 3.12728 16.6746C2.32118 15.4666 1.63647 14.036 1.53641 12.4909C1.48265 11.6607 1.48265 10.8009 1.53641 9.9707C1.81113 5.72838 5.14983 2.34913 9.34127 2.07107C10.2184 2.01289 11.1203 1.99039 12.0173 2.00372"
                    stroke="#8E8E93"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.99609 14H14.9961M7.99609 9H11.4961"
                    stroke="#8E8E93"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.2335 4.17565V2.78529C16.2335 2.57992 16.2421 2.372 16.3157 2.18022C16.5116 1.66974 17.0307 1.00098 17.9756 1.00098C18.9206 1.00098 19.46 1.66974 19.6559 2.18022C19.7296 2.372 19.7381 2.57992 19.7381 2.78529V4.17565M16.303 9.9985H19.689C20.6859 9.9985 21.494 9.192 21.494 8.19698V6.19563C21.494 5.20065 20.6859 4.39406 19.689 4.39406H16.303C15.3062 4.39406 14.4981 5.20065 14.4981 6.19563V8.19698C14.4981 9.192 15.3062 9.9985 16.303 9.9985Z"
                    stroke="#8E8E93"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>1-1 Chats</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contacts"
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "nav__active" : ""
                }
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.2461 7C13.2461 9.20914 11.4552 11 9.24609 11C7.03695 11 5.24609 9.20914 5.24609 7C5.24609 4.79086 7.03695 3 9.24609 3C11.4552 3 13.2461 4.79086 13.2461 7Z"
                    stroke="#8E8E93"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.2461 11C17.4552 11 19.2461 9.20914 19.2461 7C19.2461 4.79086 17.4552 3 15.2461 3"
                    stroke="#8E8E93"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.2461 14H7.24609C4.48467 14 2.24609 16.2386 2.24609 19C2.24609 20.1046 3.14152 21 4.24609 21H14.2461C15.3507 21 16.2461 20.1046 16.2461 19C16.2461 16.2386 14.0075 14 11.2461 14Z"
                    stroke="#8E8E93"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.2461 14C20.0075 14 22.2461 16.2386 22.2461 19C22.2461 20.1046 21.3507 21 20.2461 21H18.7461"
                    stroke="#8E8E93"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>Contacts</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/calls"
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "nav__active" : ""
                }
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.27371 11.9424C3.32569 10.2893 2.86794 8.93948 2.59193 7.57121C2.18371 5.54758 3.1179 3.57081 4.66547 2.30947C5.31954 1.77638 6.06932 1.95852 6.45609 2.6524L7.32927 4.21891C8.02138 5.46057 8.36743 6.08139 8.29879 6.73959C8.23016 7.39779 7.76346 7.93386 6.83006 9.00601L4.27371 11.9424ZM4.27371 11.9424C6.1926 15.2883 9.20393 18.3013 12.5537 20.2224M12.5537 20.2224C14.2068 21.1704 15.5566 21.6282 16.9249 21.9042C18.9485 22.3124 20.9253 21.3782 22.1866 19.8306C22.7197 19.1766 22.5376 18.4268 21.8437 18.04L20.2772 17.1668C19.0355 16.4747 18.4147 16.1287 17.7565 16.1973C17.0983 16.2659 16.5622 16.7326 15.4901 17.666L12.5537 20.2224Z"
                    stroke="#8E8E93"
                    stroke-width="1.5"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.4961 6.83185C15.9193 7.43624 17.0599 8.57677 17.6643 10M15.1501 2C18.6873 3.02076 21.4752 5.80852 22.4961 9.34563"
                    stroke="#8E8E93"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
                <span>Calls</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div style={{ minWidth: "300px" }} className={navStyles.part__3}>
          {/* <Menu
            onClick={onClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={itemsNot}
          /> */}
          <Menu
            onClick={onClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={itemsProfile}
            className={navStyles.menu__account}
            style={{ minWidth: "300px", justifyContent: "end" }}
          />
        </div>
      </nav>

      <div className={navStyles.nav__mobile}>
        <div className={navStyles.bar}>
          <NavLink
            to="/spaces"
            className={({ isActive, isPending }) =>
              isPending
                ? `${navStyles.icon__wrap} ${navStyles.el__1}`
                : isActive
                ? `${navStyles.icon__wrap} ${navStyles.el__1} ${navStyles.nav__mobile__active}`
                : `${navStyles.icon__wrap} ${navStyles.el__1}`
            }
          >
            <div className={navStyles.icon}>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5911 17.4868C16.1467 17.2526 18.9789 14.4069 19.2119 10.8344C19.2575 10.1353 19.2575 9.41131 19.2119 8.71219C18.9789 5.13968 16.1467 2.29401 12.5911 2.05985C11.3781 1.97997 10.1116 1.98013 8.90108 2.05985C5.34552 2.29401 2.51334 5.13968 2.28029 8.71219C2.23469 9.41131 2.23469 10.1353 2.28029 10.8344C2.36517 12.1356 2.94601 13.3403 3.62981 14.3576C4.02685 15.0697 3.76482 15.9586 3.35127 16.735C3.05309 17.2948 2.904 17.5747 3.02371 17.7769C3.14341 17.9791 3.41081 17.9856 3.9456 17.9985C5.00321 18.024 5.71637 17.7269 6.28247 17.3134C6.60353 17.0788 6.76407 16.9615 6.87471 16.9481C6.98535 16.9346 7.20309 17.0234 7.6385 17.2011C8.02983 17.3608 8.48421 17.4593 8.90108 17.4868C10.1116 17.5665 11.3781 17.5666 12.5911 17.4868Z"
                  stroke="#8E8E93"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.74609 12H13.7461M7.74609 8H10.7461"
                  stroke="#8E8E93"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.74609 20C9.79647 20.8697 11.0606 21.4238 12.5096 21.5188C13.6513 21.5937 14.8432 21.5936 15.9826 21.5188C16.3749 21.4931 16.8026 21.4007 17.1709 21.251C17.5806 21.0845 17.7856 21.0012 17.8898 21.0138C17.9939 21.0264 18.145 21.1364 18.4472 21.3563C18.98 21.744 19.6512 22.0225 20.6466 21.9986C21.1499 21.9865 21.4016 21.9804 21.5142 21.7909C21.6269 21.6013 21.4866 21.3389 21.2059 20.8141C20.8167 20.0862 20.5701 19.2529 20.9438 18.5852C21.5874 17.6315 22.134 16.5021 22.2139 15.2823C22.2568 14.6269 22.2568 13.9481 22.2139 13.2927C22.1607 12.4799 21.9634 11.7073 21.6473 11"
                  stroke="#8E8E93"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p className={navStyles.label}>Spaces</p>
          </NavLink>
          <NavLink
            to="/messages"
            className={({ isActive, isPending }) =>
              isPending
                ? `${navStyles.icon__wrap} ${navStyles.el__2}`
                : isActive
                ? `${navStyles.icon__wrap} ${navStyles.el__2} ${navStyles.nav__mobile__active}`
                : `${navStyles.icon__wrap} ${navStyles.el__2}`
            }
          >
            <div className={navStyles.icon}>
              <svg
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.4961 12.4909C21.2214 16.7332 17.8827 20.1125 13.6912 20.3905C12.2613 20.4854 10.7683 20.4852 9.34127 20.3905C8.84985 20.3579 8.31421 20.2409 7.85289 20.0513C7.33961 19.8403 7.08293 19.7348 6.9525 19.7508C6.82207 19.7668 6.63283 19.9061 6.25434 20.1846C5.587 20.6757 4.7463 21.0285 3.49955 20.9982C2.86911 20.9829 2.55389 20.9752 2.41278 20.7351C2.27166 20.495 2.44741 20.1626 2.79892 19.4978C3.28644 18.5758 3.59532 17.5203 3.12728 16.6746C2.32118 15.4666 1.63647 14.036 1.53641 12.4909C1.48265 11.6607 1.48265 10.8009 1.53641 9.9707C1.81113 5.72838 5.14983 2.34913 9.34127 2.07107C10.2184 2.01289 11.1203 1.99039 12.0173 2.00372"
                  stroke="#8E8E93"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.99609 14H14.9961M7.99609 9H11.4961"
                  stroke="#8E8E93"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.2335 4.17565V2.78529C16.2335 2.57992 16.2421 2.372 16.3157 2.18022C16.5116 1.66974 17.0307 1.00098 17.9756 1.00098C18.9206 1.00098 19.46 1.66974 19.6559 2.18022C19.7296 2.372 19.7381 2.57992 19.7381 2.78529V4.17565M16.303 9.9985H19.689C20.6859 9.9985 21.494 9.192 21.494 8.19698V6.19563C21.494 5.20065 20.6859 4.39406 19.689 4.39406H16.303C15.3062 4.39406 14.4981 5.20065 14.4981 6.19563V8.19698C14.4981 9.192 15.3062 9.9985 16.303 9.9985Z"
                  stroke="#8E8E93"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p className={navStyles.label}>Messages</p>
          </NavLink>
          <NavLink
            to="/contacts"
            className={({ isActive, isPending }) =>
              isPending
                ? `${navStyles.icon__wrap} ${navStyles.el__3}`
                : isActive
                ? `${navStyles.icon__wrap} ${navStyles.el__3} ${navStyles.nav__mobile__active}`
                : `${navStyles.icon__wrap} ${navStyles.el__3}`
            }
          >
            <div className={navStyles.icon}>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2461 7C13.2461 9.20914 11.4552 11 9.24609 11C7.03695 11 5.24609 9.20914 5.24609 7C5.24609 4.79086 7.03695 3 9.24609 3C11.4552 3 13.2461 4.79086 13.2461 7Z"
                  stroke="#8E8E93"
                  stroke-width="1.5"
                />
                <path
                  d="M15.2461 11C17.4552 11 19.2461 9.20914 19.2461 7C19.2461 4.79086 17.4552 3 15.2461 3"
                  stroke="#8E8E93"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.2461 14H7.24609C4.48467 14 2.24609 16.2386 2.24609 19C2.24609 20.1046 3.14152 21 4.24609 21H14.2461C15.3507 21 16.2461 20.1046 16.2461 19C16.2461 16.2386 14.0075 14 11.2461 14Z"
                  stroke="#8E8E93"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.2461 14C20.0075 14 22.2461 16.2386 22.2461 19C22.2461 20.1046 21.3507 21 20.2461 21H18.7461"
                  stroke="#8E8E93"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p className={navStyles.label}>Contacts</p>
          </NavLink>
          <NavLink
            to="/calls"
            className={({ isActive, isPending }) =>
              isPending
                ? `${navStyles.icon__wrap} ${navStyles.el__4}`
                : isActive
                ? `${navStyles.icon__wrap} ${navStyles.el__4} ${navStyles.nav__mobile__active}`
                : `${navStyles.icon__wrap} ${navStyles.el__4}`
            }
          >
            <div className={navStyles.icon}>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.27371 11.9424C3.32569 10.2893 2.86794 8.93948 2.59193 7.57121C2.18371 5.54758 3.1179 3.57081 4.66547 2.30947C5.31954 1.77638 6.06932 1.95852 6.45609 2.6524L7.32927 4.21891C8.02138 5.46057 8.36743 6.08139 8.29879 6.73959C8.23016 7.39779 7.76346 7.93386 6.83006 9.00601L4.27371 11.9424ZM4.27371 11.9424C6.1926 15.2883 9.20393 18.3013 12.5537 20.2224M12.5537 20.2224C14.2068 21.1704 15.5566 21.6282 16.9249 21.9042C18.9485 22.3124 20.9253 21.3782 22.1866 19.8306C22.7197 19.1766 22.5376 18.4268 21.8437 18.04L20.2772 17.1668C19.0355 16.4747 18.4147 16.1287 17.7565 16.1973C17.0983 16.2659 16.5622 16.7326 15.4901 17.666L12.5537 20.2224Z"
                  stroke="#8E8E93"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.4961 6.83185C15.9193 7.43624 17.0599 8.57677 17.6643 10M15.1501 2C18.6873 3.02076 21.4752 5.80852 22.4961 9.34563"
                  stroke="#8E8E93"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <p className={navStyles.label}>Calls</p>
          </NavLink>
        </div>
      </div>
      <div
        style={{
          height:
            document.getElementById("navCon") !== null
              ? // @ts-ignore
                document?.getElementById("navCon").offsetHeight
              : "78px",
        }}
      ></div>
    </div>
  );
}

export default Nav;
